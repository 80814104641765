/*
==================================================
シンプル ブルー
==================================================
*/
$mainColor: hsl(215,100%,25%);
$navBgColor: hsl(215,33%,90%);
$headNavColor: hsl(215,61%,31%);
$headNavhoverColor: hsl(215,99%,52%);
$backgroundColor: #fff;
$textColor: #333;
$linkColor: #06c;
$linkHoverColor: hsl(215,99%,52%);
$menuLiHover: hsl(44,100%,93%);
$cateTtlLine:hsl(215,100%,25%);
$cateboxColor: hsl(215,100%,99%);
$searchBox: hsl(215,100%,95%);
