@charset "UTF-8";

@import "simple-blue";

/**** Simple Type ******/

/*
==================================================
スタイルの初期化
==================================================
*/

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  &:before, &:after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption {
  outline: 0;
  border: 0;
  background: transparent;
  vertical-align: baseline;
  font-size: 100%;
}
blockquote {
  &:before, &:after {
    content: '';
    content: none;
  }
}
q {
  &:before, &:after {
    content: '';
    content: none;
  }
}
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  -webkit-text-size-adjust: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
div, p, h1, h2, h3, h4, h5 {
  display: block;
  text-align: left;
}
h1, h2, h3, h4, h5 {
  line-height: 1.4;
}
img {
  display: inline-block;
  max-width: 100%;
  border: 0;
}

@media screen and (max-width: 899px) {
  img {
    max-width: 100%;
    height: auto;
    vertical-align: top;
    font-size: 0;
    line-height: 0;
  }
}

@media screen and (max-width: 640px) {
  img {
    float: none !important;
    max-width: 100% !important;
  }
}

a {
  margin: 0;
  padding: 0;
  background: transparent;
  vertical-align: baseline;
  font-size: 100%;
}

:focus {
  outline: 0;
}

ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

input, select {
  vertical-align: middle;
}

figure {
	text-align: center;
  margin: 0 .4rem .8rem;
}
figure figcaption{
	padding-top: .4em;
	font-size: .8em;
	text-align: center;
	line-height: 1.2;
}

/*
==================================================
 common修正
==================================================
*/

/* エントリーリスト thumbnail */
#entrylist dl > div {
  clear: both;
}
// #entrylist .entry_thumb a {
// 	display: block;
// 	position: relative;
// 	width: 80px;
// 	height: 80px;
// 	overflow: hidden;
// }
// #entrylist .entry_thumb img {
// 	position: absolute;
// 	left: 50%;
// 	margin: 0 auto;
// 	width: auto;
// 	max-width: inherit;
// 	height: 80px;
// 	 -webkit-transform:  translateX(-50%);
// 	transform: translateX(-50%);
// }

/* 文字装飾 */
.ylw {
  padding: 2px;
  font-weight: bold;
  background: linear-gradient(transparent 50%, #ff9 50%);
}
.red {
  padding: 2px;
  color: #ff372b;
  font-weight: bold;
}

blockquote {
  position: relative;
  padding: 35px 15px 10px 15px;
  box-sizing: border-box;
  font-style: italic;
  background: #f5f5f5;
  color: #777777;
  border-left: 4px solid #9dd4ff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14);
}

blockquote:before{
  display: inline-block;
  position: absolute;
  top: 10px;
  left: 15px;
  content: "\f10d";
  font-family: FontAwesome;
  color: #9dd4ff;
  font-size: 28px;
  line-height: 1;
  font-weight: 900;
}

blockquote p {
  padding: 0;
  margin: 7px 0;
  line-height: 1.7;
}

blockquote cite {
  display: block;
  text-align: right;
  color: #888888;
  font-size: 0.9em;
}

/* 矢印 */
.arrow_blue, .arrow_orange, .arrow_black, .arrow_red {
  overflow: hidden;
  margin-top: 3px;
  margin-bottom: 3px;
  padding-left: 25px;
  background-position: left center;
  background-repeat: no-repeat;
  font-weight: bold;
  line-height: 1.6;
}
.arrow_link {
  margin-top: 3px;
  margin-bottom: 3px;
  padding-left: 25px;
  background-image: url(img/arrow_orange.gif);
  background-position: left center;
  background-repeat: no-repeat;
}

/* 吹き出し・box */
.bln {
  font-size: .95rem;
}

.nmlbox, .dottbox, .ylwbox, .graybg {
  margin: 1rem .6rem;
  padding: 1rem;
}

.ylwbox {
  background-color: #ffffcc;
  border-color: $mainColor;
}


/* お知らせ */
.alert, .accept, .attention, .thint {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-left: 55px;
  background-position: 8px 3px;
  font-size: 1rem;
  line-height: 1.6;
}

/* FAQ */
.question1,.question2 {
  font-size: 1.1rem;
  line-height: 1.3;
}
.answer1,.answer2 {
  font-size: 1rem;
  line-height: 1.4;
}

/* 口コミ */
.user1, .user2, .user3 {
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 20px 12px 20px 100px;
  min-height: 70px;
  border: 1px solid #c5d7ed;
  border-radius: 3px;
  background-color: #eff4fa;
  background-position: 12px 12px;
  background-repeat: no-repeat;
  background-size: 82px auto;
  font-size: 1rem;
  line-height: 1.6;
}

@media screen and (max-width: 640px) {
  .user1, .user2, .user3 {
    padding: 100px 10px 10px;
    background-position: center 10px;
  }
  .user1.noimage {
    padding: 10px 10px 10px;
  }
}

.user1 {
  background-image: url(img/user1.png);
}
.user2 {
  background-image: url(img/user2.png);
}
.user3 {
  background-image: url(img/user3.png);
}
.noimage {
  padding-left: 25px;
  min-height: 15px;
  background-image: none;
}

/*
==================================================
 textstyles修正
==================================================
*/
.txt-border p {
  font-size: 1rem;
  line-height: 30px;
}


/*
==================================================
 全サイト共通アイテム
==================================================
*/
/* フォントサイズ */

html {font-size: 16px;}
@media screen and (min-width: 641px) {
  html {font-size: 17px;}
}
@media screen and (min-width: 900px) {
  html {font-size: 18px;}
}



/* 共通 */
.lbg {
  line-height: 1.6;
}

.center {
  margin: 0 auto;
  text-align: center;
}

.pc {
  display: none !important;
}
.sp {
  display: block !important;
}

@media screen and (min-width: 768px) {
  .pc {
    display: block !important;
  }
  .sp {
    display: none !important;
  }
}


/*
==================================================
基本設定
==================================================
*/

body {
  background-color: $backgroundColor;
  color: $textColor;
  text-align: center;
  letter-spacing: -.001em;
  font-size: 1rem;
  font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック",sans-serif;
  line-height: 1.8;
}

h1, h2, h3, h4, h5, .title, dt {
  font-weight: 700;
}

.clear {
  clear: both;
  font-size: 1px;
  line-height: 0;
}

/* useful
==================================================
*/

ul {
  margin: 0 10px;
  padding: 10px 0;
  li {
    padding: 0 0 0 16px;
    background-image: url(img/li.png);
    background-position: 5px 10px;
    background-repeat: no-repeat;
  }
}

ol {
  margin: 0 10px;
  padding: 10px 0 10px 25px;
  list-style-type: decimal;
}

/* 動画 */
.youtube {
  text-align: center;
}
@media screen and (max-width: 640px) {
  .youtube {
    position: relative;
    padding-top: 56.25%;
    padding-bottom:30px;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      }
  }
  
}

/* リンク
==================================================
*/

a {
  color: $linkColor;
  transition: all .4s;
  &:hover {
    color: $linkHoverColor;
    text-decoration: none;
    opacity: .8;
    img {
      background: none !important;
      opacity: .6;
    }
  }
  img {
    background: none !important;
  }
}

#main a:hover, #menu a:hover, #rmenu a:hover, #pagetop a:hover {
  position: relative;
  top: 1px;
  left: 1px;
}

@media screen and (max-width: 640px) {
  #main a:hover, #menu a:hover, #rmenu a:hover, #pagetop a:hover {
    position: static;
    top: 0;
    left: 0;
  }
}

/*
==================================================
レイアウト
==================================================
*/

#wrapper {
  overflow: hidden;
  text-align: center;
}

#contents {
  position: relative;
  margin: 0 auto;
  padding: 20px 0 50px;
  width: 1000px;
  text-align: center;
}

#layoutbox {
  overflow: hidden;
  padding: 5px 10px;
}

#siteNavi {
  margin: 5px 0 8px;
  padding: 5px 20px;
  font-size: 15px;
}

@media screen and (max-width: 899px) {
  #contents {
    padding: 15px 10px 50px;
    width: auto;
  }
  #layoutbox {
    padding: 5px;
  }
}

@media screen and (max-width: 640px) {
  #contents {
    padding: 0 0 50px;
  }
  #layoutbox {
    padding: 5px 0;
  }
  #siteNavi {
    margin-bottom: 5px;
    padding: 0 10px;
    font-size: 13px;
  }
}

/* トップへ戻る
==================================================
*/

#pagetop {
  position: fixed;
  right: 50px;
  bottom: 50px;
  z-index: 5;
  display: none;
  clear: both;
  a {
    &:hover {
      opacity: 1;
    }
    display: block;
    overflow: hidden;
    width: 50px;
    height: 50px;
    background-image: url(img/pagetop_button.png);
    background-position: left top;
    background-repeat: no-repeat;
    text-indent: 200%;
    white-space: nowrap;
    opacity: .9;
  }
}

#move-page-top {
  display: block;
  cursor: pointer;
}

@media screen and (max-width: 640px) {
  #pagetop {
    right: 5px;
    bottom: 5px;
    overflow: hidden;
  }
}

/* 検索
==================================================
*/

#searchbox {
  position: relative;
  margin-left: 0;
  padding: 8px;
	width: 280px;
  height: 48px;
  // background-color: $searchBox;
  font-size: 16px;
	text-align: left;
  form {
    position: relative;
    margin: 0;
    padding: 0;
    &::before {
      display: block;
      content:"\f002";
      position: absolute;
      top: 0;
      right: 4px;
      z-index: 1;
      width: 28px;
      height: 28px;
      font-family: FontAwesome;
      font-size: 20px;
      line-height: 28px;
      color: #403829;
      text-align: center;
    }
  }
  input.skwd {
    background-color: #fff;
    border: 1px solid #bbb;
    width: 260px;
    height: auto;
    padding: .4em 28px .4em .5em;
    font-size: 14px;
    cursor: auto;
  }
  input[type="submit"] {
    position: absolute;
    top: 0;
    right: 4px;
    z-index: 2;
    width: 28px;
    height: 28px;
    background-color: transparent;
	  border: none;
    vertical-align: middle;
    cursor: pointer;
  }
}
@media screen and (max-width: 899px) {
	#searchbox {
		width: 100%;
	}
	#searchbox input.skwd {
		width: 100%;
	}
}

/* useful
==================================================
*/

.txt-img img {
  margin: 0 8px 8px 8px;
}

.center {
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center;
}

.float-l {
  float: left;
}

.float-r {
  float: right;
}

.float-l img, .float-r img {
  margin-bottom: 20px;
}

.float-l img {
  margin-right: 20px;
}

.float-r img {
  margin-left: 20px;
}

.clear, .FloatEnd {
  clear: both;
  height: 0;
  font-size: 1px;
  line-height: 0;
}

.label_green,
.label_orange {
  padding: 0 .7em;
  color: #fff;
  font-weight: 700;
  border-radius: 3px;
}

.label_green {
  background-color: green;
}
.label_orange {
  background-color: darkorange;
}


@media screen and (max-width: 640px) {
  .txt-img {
    margin: 10px auto;
    padding: 0 10px;
    text-align: center;
    float: none !important;
    img {
      float: none !important;
    }
  }
  .txt-img.timg-r, .txt-img.timg-l {
    max-width: 100%;
 }
  .float-l {
    p {
      float: none;
      text-align: center;
    }
    float: none;
    text-align: center;
  }
}

/*
==================================================
カラム
==================================================
*/
/* fit-sidebar */
.fit-sidebar-fixed {
  position: fixed;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.fit-sidebar-blank {
  z-index: 0;
  visibility: hidden;
  background-color: transparent;
}



/* ヘッダー
==================================================
*/
header {
  margin: 0 auto;
  height: auto !important;
  text-align: center;
}

#header_sp {
  margin: 0 auto;
  height: auto !important;
  text-align: center;
}
#header {
  display: none;
}

@media screen and (min-width: 768px) {
  header {
    position: relative;
    width: auto;
  }
  #header_sp {
    display: none;
  }
  #header {
    display: block;
    margin: 0 auto;
    max-width: 1000px;
    height: auto !important;
    text-align: center;
  }
}



/* トップ領域
==================================================
*/

*:first-child + html body {
  padding-left: 1px;
}
.header__top {
  background-color:$mainColor;
}

#logo {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  line-height: 0;
  img {
    width: calc(100% - 50px);
  }
}
@media screen and (min-width:768px){
  #logo{
    img {
      width: 320px;
    } 
  }
}


/* トップメニュー
==================================================
*/

#nav {
  display: none;
}

/* スマホnavi横スライド形式 */
#overlay {
  display: none;
  position: fixed;
  top: 0;
  z-index: 799;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.5);
}
#navSp {
  margin: 0;
  position: relative;
  top: 0;
  #navSp__logo {
    width:  calc(100% + 10px);
    margin-left: -10px;
    margin-bottom: 12px;
    background-color: $mainColor;
    a {
      display: block;
      max-width: calc(100% - 55px);
    }
  }
  #topmenubtn {
    position: fixed;
    top: 12px;
    right: 10px;
    z-index: 1000;
    width: 45px;
    height: 45px;
    border: 1px solid $mainColor;
    border-radius: 4px;
    background: linear-gradient(to bottom, #fff 0%, #eee 100%);
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
    color: $mainColor;
    font-size: 9px;
    line-height: 26px;
    padding-top: 22px;
    text-align: center;
    cursor: pointer;
    &::before {
      border-top: 2px solid $mainColor;
      border-bottom: 2px solid $mainColor;
      content: "";
      position: absolute;
      top: 8px;
      left: 5px;
      width: 33px;
      height: 10px;
    }
    &::after {
      border-top: 2px solid $mainColor;
      content: "";
      position: absolute;
      top: 23px;
      left: 5px;
      width: 33px;
      height: 0;
    }
  }
}

#navSp__box {
  position: fixed;
  top: 0;
  right: -300px;
  z-index: 800;
  padding: 10px 1px 10px 10px;
  width: 300px;
  height: 100%;
  overflow-y: scroll;
  background-color: #F5F5F5;
  box-shadow: -5px 0px 5px rgba(0,0,0,.2);
  transition: transform .4s ease-out;
  transform: translateX(0);
  &.menuon {
    transform: translateX(-100%);
  }
  .navSp__menu ul {
    margin-left: 0;
    margin-right: 0;
  }
  .navSp__menu li {
    display: block;
    background-image: none;
    border-top: 1px solid #cfd4da;
    border-bottom: 1px solid #f3f9ff;
    padding-left: 0;
    font-weight: 700;
    text-align: left;
    &:last-child {
      border-bottom: 2px solid #cfd4da;
    }
  a {
    display: block;
    position: relative;
    overflow: visible;
    padding: .8em 1em .7em 10px;
    height: auto;
    border-bottom: none;
    color: $textColor;
    text-align: left;
    line-height: 1.2;
    text-decoration: none;
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      right: 8px;
      margin-top: -2px;
      width: 5px;
      height: 5px;
      border-top: 1px solid $mainColor;
      border-right: 1px solid $mainColor;
      border-bottom: none;
      border-left: none;
      background-color: transparent;
      transform: rotate(45deg);
    }
  }
  }
  .navSp__menu li.l2 {
    padding-left: 10px;
    font-weight: 400;
  }
}


@media screen and (min-width: 768px) {
  #nav {
    display: block;
    width: 100%;
    background-color: $navBgColor;
  }
  #navSp,#overlay,#topmenubtn {
    display: none;
  }
  #topmenu {
    max-width: 1000px;
    margin: 0 auto;
  }
  #topmenu {
    ul, li {
      background-image: none;
      list-style-type: none;
      list-style-image: none;
    }
    ul {
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      padding: 2px 0 0;
      height: auto;
      font-weight: 700;
    }
    li {
      flex-basis: 20%;
      padding: 0;
      border-right: 1px solid #c9c9c9;
      background: linear-gradient(to bottom, #fff 0%,#f6f6f6 47%,#ededed 100%);
      box-shadow: 0 -1px 0 rgba(255, 255, 255, 1) inset;
      cursor: pointer;

      a {
        display: block;
        overflow: hidden;
        padding: 0 2px;
        height: 49px;
        border-bottom: 2px solid $headNavColor;
        color: $headNavColor;
        list-style-image: none;
        text-align: center;
        text-decoration: none;
        font-size: .85em;
        line-height: 47px;
        &:hover {
          border-bottom: 2px solid $headNavhoverColor;
          background: linear-gradient(to bottom, #fff 1%, #fffdf8 50%, #fff6e6 51%, #e6e1d9 99%);
          color: $linkHoverColor;
          opacity: .8;
        }
      }
    }
  }
}
/*
@media screen and (max-width: 899px) {
  #topmenu {
    ul {
      display: table;
      clear: both;
      margin: 0;
      width: 100%;
      background: none repeat scroll 0 0 transparent;
    }
    li {
      display: table-cell;
      float: none;
      margin: 0;
      padding: 0;
      width: auto;
      &:before {
        position: static;
        top: auto;
        left: auto;
        clear: both;
        width: auto;
        height: auto;
        background-color: transparent;
        content: "";
      }
      a {
        position: relative;
      }
    }
  }
}
*/



/*
==================================================
メインコンテンツ
==================================================
*/
/* 
==================================================
*/

#main {
  float: left;
  overflow: hidden;
  width: 720px;
  background-color: #fff;
  box-shadow: 2px 2px 3px #cdcdcd,-1px -1px 3px #cdcdcd;
  word-wrap: break-word;
  .text {
    overflow: hidden;
    margin: 10px 15px 20px;
    padding: 10px;
  }
}

@media screen and (max-width: 899px) {
  #main {
    width: 72.2222222222%;
  }
}

@media screen and (max-width: 640px) {
  #main {
    float: none;
    width: 100%;
  }
}

#text1, #text2, #text3, #text4, #text5, #text6, #text7, #text8, #text9, #text10, #space1, #space2 {
  overflow: hidden;
  margin: 10px 15px 20px;
  padding: 10px;
}

@media screen and (max-width: 640px) {
  #main .text, #text1, #text2, #text3, #text4, #text5, #text6, #text7, #text8, #text9, #text10, #space1, #space2 {
    margin: 10px 5px 15px;
    padding: 10px 5px;
  }
}

/* 見出し
==================================================
*/

#main {
  h1, h2 {
    position: relative;
    display: block;
    margin-bottom: 5px;
    padding: 10px 5px 8px 30px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    background: linear-gradient(to bottom, #fff 0%, #f0f0f0 100%);
    box-shadow: 0 -1px 0 rgba(255, 255, 255, 1) inset;
    color: $textColor;
    text-shadow: 0 0 1px rgba(255, 255, 255, 0.3);
    font-size: 20px;
  }
  h1::after, h2::after {
    position: absolute;
    top: .5em;
    left: .5em;
    z-index: 2;
    width: 6px;
    height: calc(100% - 1em);
    border-radius: 4px;
    background-color: $mainColor;
    content: "";
  }
  .text h2 {
    margin: 0 0 5px -10px;
  }
}

#text1 h2, #text2 h2, #text3 h2, #text4 h2, #text5 h2, #text6 h2, #text7 h2, #text8 h2, #text9 h2, #text10 h2, #space1 h3, #space2 h3 {
  margin: 0 0 5px -10px;
}

#main {
  h3 {
    position: relative;
    margin: 10px auto 10px;
    padding: .35em 0 .25em .5em;
    border-left: 6px solid $mainColor;
    color: $textColor;
    text-shadow: 0 0 1px rgba(78, 123, 160, 0.3);
    font-size: 20px;
    line-height: 1.4;
    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0;
      border-bottom: 1px solid #ccc;
      content: "";
    }
  }
  h4 {
    margin: 3px auto;
    padding: 10px;
    padding-left: 28px;
    background-image: url(img/h5.png);
    background-position: left center;
    background-repeat: no-repeat;
    color: $textColor;
    text-shadow: 0 0 1px rgba(51, 51, 51, 0.1);
    font-size: 20px;
    line-height: 150%;
  }
  h5 {
    margin: 3px auto;
    padding-left: 22px;
    background-image: url(img/h6.png);
    background-position: left 1px;
    background-repeat: no-repeat;
    color: $textColor;
    text-shadow: 0 0 1px rgba(51, 51, 51, 0.1);
    font-size: 16px;
    line-height: 140%;
  }
  .atn02 {
    overflow: hidden;
    margin: 20px 0 10px;
    padding: 20px 20px 20px 50px;
    background-color: #f2f2f2;
    background-image: url(img/atn_02.gif);
    background-position: 14px 14px;
    background-repeat: no-repeat;
    span {
      padding-bottom: 3px;
      border-bottom: 1px dashed #c2c2c2;
      color: #4d4d4d;
      font-weight: bold;
    }
  }
}

@media all and (-webkit-min-device-pixel-ratio: 1.5) {
  #main .atn02 {
    background-image: url(img/atn_02@2x.gif);
    background-size: 22px 32px;
  }
}

@media screen and (max-width: 640px) {
  #main {
    h1, h2 {
      margin: 0;
      padding: 10px 10px 8px 28px;
    }
    .text h2 {
      margin: 20px -10px 10px;
    }
    h3 {
      padding: 3px 8px 0 10px;
    }
  }
  #text1 h2, #text2 h2, #text3 h2, #text4 h2, #text5 h2, #text6 h2, #text7 h2, #text8 h2, #text9 h2, #text10 h2, #space1 h3, #space2 h3 {
    margin: 20px -10px 10px;
  }
}

/*
==================================================
サイドメニュー
==================================================
*/
/* サイドメニュー
==================================================
*/

#menu {
  img {
    height: auto;
    max-width: 100%;
  }
  float: right;
  overflow: hidden;
  width: 230px;
  ul {
    &#newEntry, &#entryarchive {
      padding: 0 0 5px 0;
      background-image: url(img/menubox_bg.gif);
      background-position: center bottom;
      background-repeat: repeat-x;
    }
  }
}

#rmenu ul {
  &#newEntry, &#entryarchive {
    padding: 0 0 5px 0;
    background-image: url(img/menubox_bg.gif);
    background-position: center bottom;
    background-repeat: repeat-x;
  }
}

#menu .rankbox {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e6e6e6;
  font-size: 12px;
  img {
    width: 185px;
  }
}

.menubox {
  margin-bottom: 25px;
}

.menulist {
  overflow: hidden;
  margin-bottom: 0 !important;
}

#menu .title, #rmenu .title, #menu h4, #rmenu h4 {
  margin: 0 3px;
  border-top: 2px solid $mainColor;
  border-bottom: 1px solid #ccc;
  background: linear-gradient(to bottom, #fff 0%, #f0f0f0 100%);
  box-shadow: 2px 0 3px #cdcdcd,-1px -1px 3px #cdcdcd;
}

#menu .title span, #rmenu .title span, #menu h4 span, #rmenu h4 span {
  display: block;
  padding: 14px 8px 14px 8px;
  color: #252525;
  text-align: center;
  text-shadow: 0 0 1px rgba(255, 255, 255, 0.3);
  font-size: .94rem;
  line-height: 1.3;
}

.menulist {
  ul {
    margin: 0 3px 25px;
    padding: 0 0 5px 0;
    background-color: #fff;
    box-shadow: 2px 2px 3px #cdcdcd,-1px -1px 3px #cdcdcd;
  }
  li {
    position: relative;
    padding: 0;
    border-bottom: 1px solid #e6e6e6;
    background: none repeat scroll 0 0 transparent;
    line-height: 110%;
    a {
      display: block;
      padding: 12px 10px 12px 25px;
      background-image: url(img/menu_bg.png);
      background-position: 8px 14px;
      background-size: 12px 12px;
      background-repeat: no-repeat;
      color: $textColor;
      text-decoration: none;
      font-weight: bold;
      font-size: 15px;
      &:hover {
        background-color: $menuLiHover;
        color: $linkHoverColor;
        opacity: .8;
        transition: all .4s;
      }
    }
  }
  .l2 a, .l3 a {
    padding-right: 8px;
    padding-left: 25px;
    background-image: url(img/menu_bg3.png);
    background-position: 12px 18px;
    background-size: auto;
    font-weight: normal;
  }
}

@media screen and (max-width: 899px) {
  #menu {
    width: 24.4444444444%;
  }
  #column2 .menulist li a {
    position: relative;
  }
  .menulist {
    .l2 a, .l3 a {
      position: relative;
    }
  }
}

@media screen and (max-width: 640px) {
  #menu {
    float: none;
    width: 100%;
    .title {
      margin-left: 0;
    }
  }
  #rmenu .title, #menu h4, #rmenu h4 {
    margin-left: 0;
  }
  #menu .title span, #rmenu .title span, #menu h4 span, #rmenu h4 span {
    font-size: 18px;
  }
  .menulist {
    ul {
      margin-bottom: 5px;
    }
    .l2, .l3 {
      display: none;
    }
  }
  .menubox {
    margin-bottom: 0;
  }
  #menu .title span, #rmenu .title span, #menu h4 span, #rmenu h4 span {
    border-top: 1px solid #e6e6e6;
    border-right: none;
    border-bottom: 1px solid #e6e6e6;
    border-left: none;
  }
}

/* バナー
==================================================
*/

.bannerbox {
  position: relative;
  margin-bottom: 10px;
  border-radius: 6px;
  background-color: #fff;
  ul {
    padding: 5px;
  }
  li {
    margin-bottom: 5px;
    padding: 0;
    background: none repeat scroll 0 0 transparent;
    a {
      font-size: 15px;
      img {
        vertical-align: bottom;
      }
    }
  }
}

@media screen and (max-width: 899px) {
  .bannerbox li:before {
    position: static;
    top: auto;
    left: auto;
    width: auto;
    height: auto;
    background-color: transparent;
    content: "";
  }
  .menulist li:before {
    background-color: transparent;
  }
}

@media screen and (max-width: 640px) {
  .bannerbox li {
    margin-bottom: 10px;
    text-align: center;
  }
}

/* 最新記事
==================================================
*/

ul {
  &#newEntry {
    margin: 0 3px 25px;
    background-color: #fff;
    box-shadow: 2px 2px 3px #cdcdcd,-1px -1px 3px #cdcdcd;
    li {
      padding: 0;
      border-bottom: 1px solid #e6e6e6;
      background: none;
      font-size: 14px;
    }
  }
  &#entryarchive li {
    padding: 0;
    border-bottom: 1px solid #e6e6e6;
    background: none;
    font-size: 14px;
    line-height: 1.6;
  }
  &#newEntry li a, &#entryarchive li a {
    display: block;
    padding: 12px 10px 12px 25px;
    background-image: url(img/menu_bg3.png);
    background-position: 12px 20px;
    background-repeat: no-repeat;
    color: $textColor;
    text-decoration: none;
    font-weight: normal;
  }
  &#newEntry li a:hover, &#entryarchive li a:hover {
    background-color: #fff5da;
    color: $linkHoverColor;
    opacity: .8;
    filter: alpha(opacity = 80);
    transition: all .4s;
  }
}

@media screen and (max-width: 899px) {
  ul {
    &#newEntry li:before, &#entryarchive li:before {
      position: static;
      top: auto;
      left: auto;
      width: auto;
      height: auto;
      background-color: transparent;
      content: "";
    }
  }
}

/*
==================================================
フッター(幅広)
==================================================
*/

footer {
  margin-top: -50px;
  border-top: 3px solid $navBgColor;
  background-color: $mainColor;
  .inner {
    overflow: hidden;
    color: #fff;
    font-size: .72rem;
    line-height: 1.4;
  }
  a {
    color: #fff;
  }

}
.ftBox {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}
.ftBox_l,.ftBox_c,.ftBox_r{
  width:100%;
}
.ftBox_r{
  img{
    display:block;
    margin:0 auto;
    }
    p {
    position: relative;
    overflow: hidden;
    word-wrap: break-word;
	  color:ghostwhite;
    font-size: 12px;
    line-height: 1.6;
    padding: 10px 10px 5px;
    text-align:center;
    }
  #footsub {
    margin: .6rem auto 0;
    height: auto;
    max-width: 900px;
    font-size: 12px;
    letter-spacing: -.40em;
    line-height: 1.6;
    text-align: center;
    span {
      display: inline-block;
      overflow: hidden;
    }
    a {
      border-left: none;
      font-size: .67rem;
      letter-spacing: normal;
      line-height: 1.4;
      margin-left: -1px;
      padding: 0 10px;
      color: ghostwhite;
      text-decoration: none;
      &:first-child {
        border-left: none;
      }
    }
  }
}
.footermenu {
  position: static;
  margin: 0;
  padding: 0;
  width: auto;

  li{
    background-image: none;
    border-bottom: 1px solid #ddd;
    a {
    padding: 5px 10px 5px 0px;
    border-left: none;
    font-size: .88rem;
    line-height: 1.2;
    letter-spacing: normal;
    text-decoration: none;
    }
  }
  span {
    display: block;
    margin: 0;
    border-bottom: 1px solid $navBgColor;
    ::before {
      position: absolute;
      top: 0;
      bottom: 0;
        right: .5em;
      margin: auto 0;
      content: '';
      display: block;
      width: .6em;
      height: .6em;
      border-top: 1px solid $mainColor;
      border-right: 1px solid $mainColor;
      transform: rotate(45deg);
    }
  }
  a {
    position: relative;
    display: block;
    padding: 12px 10px 12px 24px;
    border-right: none;
    background-position: 8px center;
    text-decoration: none;
    font-size: 15px;
  }
}
.copyright {
  display: block;
  padding: 20px 0;
  background-color: $mainColor;
  color: #fff;
  text-align: center;
  font-size: 14px;
  line-height: 28px;
}

@media screen and (min-width:768px){
footer {
  margin: 0 auto;
  .inner {
    margin: 0 auto;
    padding: 15px 10px 5px;
    width: 970px;
    max-width: 100%;
    text-align: center;
  }
}
.ftBox_l,.ftBox_c,.ftBox_r {
	width: 32%;
	text-align: left;
}

.footermenu{
  li{
    position: relative;
    margin-bottom: .3em;
    border-bottom: none;
    &::before {
      position: absolute;
      content: "\30FB";
      top: .4em;
      left: 0;
      color: #fff;
    }
    a:hover {
      text-decoration: underline;
    }
  }
}
.ftBox_r {
  p {
    text-align: left;
  }
  #footsub {
    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

}

@media screen and (min-width: 1000px) {
  footer .inner {
    padding-top: 15px;
    text-align: left;
  }
}



/*
==================================================
最新情報
==================================================
*/

#whatsnew {
  position: relative;
  margin-bottom: 20px;
  .title {
    position: relative;
    margin-bottom: 5px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    background: linear-gradient(to bottom, #fff 0%, #f0f0f0 100%);
    box-shadow: 0 -1px 0 rgba(255, 255, 255, 1) inset;
    &::after {
      position: absolute;
      top: .5em;
      left: .5em;
      z-index: 2;
      width: 6px;
      height: calc(100% - 1em);
      border-radius: 4px;
      background-color: $mainColor;
      content: "";
    }
    span {
      display: block;
      padding: 10px 5px 8px 30px;
      color: $textColor;
      text-shadow: 0 0 1px rgba(255, 255, 255, 0.3);
      font-size: 18px;
    }
  }
  ul {
    padding: 0;
    li {
      position: relative;
      padding: 10px 15px;
      border-bottom: 1px dashed #ccc;
      background: none repeat scroll 0 0 transparent;
      font-size: 16px;
      span {
        padding-right: 10px;
        color: #008b8b;
      }
    }
  }
}

@media screen and (max-width: 899px) {
  #whatsnew {
    .title {
      position: relative;
    }
    ul li:before {
      background-color: transparent;
    }
  }
}

@media screen and (max-width: 640px) {
  #whatsnew {
    margin-bottom: 0;
    padding: 10px 0 0;
    background-color: #fbfbfb;
    .title {
      margin-right: 0;
      border-bottom: none;
    }
    ul {
      border-bottom: 1px solid #e6e6e6;
      background-color: #fff;
      &:last-child {
        border-bottom: none;
      }
      li {
        &:last-child {
          border-bottom: none;
        }
        span {
          display: block;
          padding-right: 0;
          color: #666;
        }
      }
    }
  }
}

/*
==================================================
カテゴリーリスト
==================================================
*/

#categorylist {
  padding: 10px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  .catbox {
    position: relative;
    overflow: hidden;
    width: 48%;
    margin: 0 1% 15px;
    border: 1px solid $mainColor;
    border-radius: 4px;
    background-color: $cateboxColor;
    font-size: 15px;
  }
  .thumb {
    position: relative;
    overflow: hidden;
    margin: 20px auto 0;
    width: auto;
    height: 150px;
    img {
      position: absolute;
      right: -100%;
      left: -100%;
      margin: auto;
      width: auto;
      max-width: inherit;
      height: 150px;
    }
  }
  .body {
    overflow: hidden;
  }
  .title {
    overflow: hidden;
    margin-bottom: 10px;
    padding: 10px 1em 2px;
    border-bottom: 1px dotted $cateTtlLine;
    font-weight: 700;
    a {
      display: block;
      padding: 6px 0 3px 1.5em;
      background-image: url(img/category_title.png);
      background-position: left .5rem;
      background-size: 1.2em auto;
      background-repeat: no-repeat;
      color: $linkColor;
      text-decoration: none;
      font-size: 1rem;
      line-height: 1.4;
    }
  }
  p {
    padding: 0 15px;
    font-size: 14px;
    line-height: 1.5;
  }
  .more {
    float: right;
    margin: 10px 1px 1px 0;
    padding: 0 15px 15px 0;
    height: 30px;
    a {
      display: block;
      padding-right: 19px;
      background-image: url(img/button_more2.png);
      background-position: right center;
      background-repeat: no-repeat;
      color: $linkColor;
    }
  }
}

#pagelist {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  text-align: center;
  ul {
    padding: 0;
    letter-spacing: -.4em;
  }
  li {
    display: inline-block;
    margin: 0 5px 10px;
    padding: 0;
    background: none repeat scroll 0 0 transparent;
    list-style-type: none;
    letter-spacing: normal;
  }
  a {
    display: inline-block;
    padding: 6px 15px;
    border: 1px solid #e6e6e6;
    background-color: #fbfbfb;
    color: $textColor;
    text-align: center;
    text-decoration: none;
    &:hover {
      background-color: $textColor;
      color: #fff;
    }
  }
}

@media screen and (max-width: 899px) {
  #categorylist .more a {
    position: relative;
    display: block;
    overflow: hidden;
    height: 26px;
    background-position: right 4px;
    text-align: center;
    text-indent: inherit;
    font-size: 15px;
    line-height: 24px;
    &:before {
      position: absolute;
      top: 50%;
      right: 16px;
      margin-top: -2px;
      width: 4px;
      height: 4px;
      border-top: 1px solid #fff;
      border-right: 1px solid #fff;
      border-bottom: none;
      border-left: none;
      content: "";
      transform: rotate(45deg);
    }
  }
  #pagelist li:before {
    position: static;
    top: auto;
    left: auto;
    width: auto;
    height: auto;
    background-color: transparent;
    content: "";
  }
}

@media screen and (max-width: 640px) {
  #categorylist {
    -ms-flex-direction: column;
    flex-direction: column;
    .catbox {
      width: 100%;
      padding: 15px;
      margin: 0 0 30px;
    }
    .thumb {
      float: none;
      margin: 0 auto 10px;
      height: 120px;
      img {
        position: static;
        display: block;
        margin: 0 auto;
        width: auto;
        height: 120px;
      }
    }
    .title {
      padding: 5px 0;
      a {
        padding: 4px 4px 0 22px;
      }
    }
    p {
      font-size: 13px;
      line-height: 1.5;
      padding: 0;
    }
  }
}

/*
==================================================
エントリーリスト
==================================================
*/

#entrylist {
  margin: 50px 15px 20px;
  .inner {
    overflow: hidden;
    text-align: left;
  }
  h2 {
    position: relative;
    margin: 15px -15px 10px -15px;
    padding: 10px 10px 8px 30px;
    border: 1px solid #ccc;
    border-top: 3px solid #ddd;
    background: linear-gradient(to bottom, #fff 0%, #f0f0f0 100%);
    box-shadow: 0 1px 0 rgba(255, 255, 255, 1) inset,-5px 0px 5px rgba(255, 255, 255, 1);
    color: $textColor;
    text-shadow: 1px 1px 0 #fff;
    font-size: 18px;
    &::after {
      position: absolute;
      top: 1em;
      left: .5em;
      z-index: 2;
      width: 12px;
      height: 12px;
      background-color: $mainColor;
      content: "";
      transform: rotate(45deg);
    }
  }
  dl {
    position: relative;
    top: -1px;
    overflow: hidden;
    border: 1px solid $mainColor;
    border-radius: 4px;
    background-color: $cateboxColor;
  }
  dt {
    position: relative;
    margin: 0 10px;
    padding: 15px 5px;
    border-top: 1px dotted #ccc;
    &:first-child {
      border-top: none;
    }
    .entry_title a {
      padding: 1px 0 1px 20px;
      background-image: url(img/entry_title.png);
      background-position: left center;
      background-repeat: no-repeat;
      // background-size: 18px auto;
      font-weight: 700;
      font-size: .88rem;
      line-height: 1.4;
    }
  }
  dd {
    margin-top: -10px;
    padding: 0 10px 15px 25px;
    font-size: 14px;
    line-height: 1.6;
  }
}

#searchresult {
  overflow: hidden;
  margin-bottom: 20px;
  padding: 10px;
}

#relNavigate {
  margin-bottom: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  a {
    margin-right: 10px;
    margin-left: 10px;
    font-weight: 700;
    font-size: 14px;
  }
}

@media screen and (max-width: 640px) {
  #entrylist {
    margin: 0;
    padding: 0;
    h2 {
      margin: 10px 0 10px 0;
      font-size: 18px;
    }
    dl {
      top: 0;
      margin: 5px 5px 10px;
      padding: 0 5px;
    }
    dt {
      padding: 15px 5px;
      &:first-child {
        border-top: none;
      }
      a {
        font-size: 15px;
        &:hover {
          position: relative;
        }
      }
    }
    dd {
      padding: 0 5px 15px 20px;
      font-size: 14px;
      line-height: 1.6;
    }
  }
  #relNavigate a {
    position: relative;
    z-index: 0;
    display: inline-block;
    width: 35%;
    height: 40px;
    background-color: #fbfbfb;
    color: $textColor;
    text-decoration: none;
    line-height: 40px;
    &:hover {
      position: relative;
    }
    &:first-child {
      border-top: 1px solid #e6e6e6;
      border-right: 1px solid #e6e6e6;
      border-bottom: 1px solid #e6e6e6;
    }
    &:last-child {
      border-top: 1px solid #e6e6e6;
      border-bottom: 1px solid #e6e6e6;
      border-left: 1px solid #e6e6e6;
    }
    &:before {
      position: absolute;
      top: 5px;
      z-index: -1;
      width: 29px;
      height: 29px;
      background-color: #fbfbfb;
      content: "";
      transform: rotate(45deg);
    }
    &:first-child:before {
      left: -15px;
      border-bottom: 1px solid #e6e6e6;
      border-left: 1px solid #e6e6e6;
    }
    &:last-child:before {
      right: -15px;
      border-top: 1px solid #e6e6e6;
      border-right: 1px solid #e6e6e6;
    }
  }
}

/*
==================================================
サイトマップ
==================================================
*/

#sitemaps {
  ul {
    margin: 0 0 20px 10px;
    padding: 5px 10px;
  }
  li {
    padding: 0;
    background-image: none;
    line-height: 0;
  }
  .l1 {
    margin-top: 10px;
  }
  .l2, .l3 {
    margin-top: 5px;
  }
  li a {
    display: inline-block;
    padding-left: 16px;
    background-image: url(img/menu_bg.png);
    background-position: left center;
    background-repeat: no-repeat;
    color: $textColor;
    list-style-type: none;
    list-style-image: none;
    text-decoration: none;
    line-height: 1.8;
    &:hover {
      color: $linkHoverColor;
      text-decoration: underline;
    }
  }
  .l1 a {
    font-weight: 700;
    font-size: 16px;
  }
  .l2 a {
    margin-left: 25px;
    background-image: url(img/menu_bg3.png);
    font-size: 14px;
  }
  .l3 a {
    margin-left: 45px;
    background-image: url(img/menu_bg3.png);
    background-position: left 6px;
    font-size: 13px;
  }
}

@media screen and (max-width: 640px) {
  #sitemaps {
    ul {
      padding: 0 10px;
    }
    li {
      border-bottom: 1px solid #ddd;
    }
    .l1, .l2, .l3 {
      margin-top: 0;
    }
    li a {
      display: block;
      padding: 12px 10px 12px 25px;
      text-decoration: none;
    }
    .l1 a {
      background-position: 10px 18px;
      line-height: 1.5;
    }
    .l2 a {
      margin-left: 0;
      padding: 12px 40px;
      background-position: 25px 20px;
    }
    .l3 a {
      padding: 10px 15px;
      background-position: 2px 16px;
      font-size: 13px;
    }
    li a:before {
      top: 20px;
      left: 20px;
    }
    .l2 a:before {
      top: 50%;
      right: 20px;
      left: auto;
      margin-top: -3px;
    }
    .l3 a:before {
      left: 50px;
    }
  }
}

/*
==================================================
フリースペース
==================================================
*/

#space1, #space2 {
  margin-bottom: 25px;
}

#space3, #space4, #space5, #space6 {
  padding: 10px;
  line-height: 1.4;
}

.grayline {
  margin: 10px;
  border: 1px solid #ccc;
}

.graybg {
  margin: 10px;
}

#main .grayline {
  margin: 10px;
  margin-bottom: 15px;
  padding: 10px;
}

#menu .grayline {
  margin-bottom: 15px;
  padding: 8px;
  font-size: 90%;
}

/*
==================================================
検索結果
==================================================
*/

#searchresult {
  dt {
    padding: 5px 20px;
    font-size: 16px;
  }
  dd {
    margin: 0 0 10px;
    padding: 0 20px 15px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #e6e6e6;
    line-height: 1.6;
  }
  b {
    color: $linkColor;
  }
}

@media screen and (max-width: 640px) {
  #searchresult {
    dt {
      padding: 15px 10px 5px;
    }
    dd {
      margin: 0 0 0;
      padding: 0 10px 15px;
    }
  }
}

/*
==================================================
リンクリスト
==================================================
*/

#linklist {
  overflow: hidden;
  margin-bottom: 20px;
  padding: 10px;
  dt {
    padding: 5px 0;
    font-size: 14px;
  }
  dd {
    margin: 0 0 10px;
    padding-bottom: 15px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #e6e6e6;
    line-height: 1.4;
  }
}

/*
==================================================
ポイント
==================================================
*/

#main {
  .point1, .point2, .point3, .point4, .point5, .point6, .point7, .point8, .point9, .point10 {
    display: inline-block;
    margin-top: 15px;
    margin-bottom: 3px;
    padding: 3px 5px 8px 100px;
    height: auto;
    background-position: left 3px;
    background-repeat: no-repeat;
    background-size: 90px auto;
    color: $textColor;
    font-weight: bold;
    font-size: 1.25rem;
    line-height: 1.4;
  }
  .point1 a, .point2 a, .point3 a, .point4 a, .point5 a, .point6 a, .point7 a, .point8 a, .point9 a, .point10 a {
    text-decoration: none;
  }
  .point1 {
    background-image: url(img/point1.png);
  }
  .point2 {
    background-image: url(img/point2.png);
  }
  .point3 {
    background-image: url(img/point3.png);
  }
  .point4 {
    background-image: url(img/point4.png);
  }
  .point5 {
    background-image: url(img/point5.png);
  }
  .point6 {
    background-image: url(img/point6.png);
  }
  .point7 {
    background-image: url(img/point7.png);
  }
  .point8 {
    background-image: url(img/point8.png);
  }
  .point9 {
    background-image: url(img/point9.png);
  }
  .point10 {
    background-image: url(img/point10.png);
  }
  .point1, .point2, .point3, .point4, .point5, .point6, .point7, .point8, .point9, .point10 {
    border: none;
  }
  .rank1, .rank2, .rank3, .rank4, .rank5, .rank6, .rank7, .rank8, .rank9, .rank10 {
    height: auto;
    border: none;
  }
  .rank {
    position: relative;
    margin-bottom: 20px;
  }
  .rank-container {
    position: relative;
    padding: 5px;
    background-color: #f1f1ee;
  }
  .rank-inner {
    overflow: hidden;
    padding: 10px 20px 16px 20px;
    border: 1px solid #e6e6e4;
    background-color: #fdfdfd;
  }
  .rank1, .rank2, .rank3, .rank4, .rank5, .rank6, .rank7, .rank8, .rank9, .rank10 {
    margin-bottom: 6px;
    padding: 18px 0 0 60px;
    height: auto;
    min-height: 72px;
    border: medium none;
    background-position: left 13px;
    background-size: 56px;
    background-repeat: no-repeat;
    color: navy;
    text-shadow: 0 0 1px rgba(0, 102, 204, 0.1);
    font-size: 24px;
    line-height: 1.4;
  }
  .rank {
    .rank1, .rank2, .rank3, .rank4, .rank5, .rank6, .rank7, .rank8, .rank9, .rank10 {
      margin: 0 10px 10px 0;
    }
  }
  .rank1 {
    background-image: url(img/rank1.png);
  }
  .rank2 {
    background-image: url(img/rank2.png);
  }
  .rank3 {
    background-image: url(img/rank3.png);
  }
  .rank4 {
    background-image: url(img/rank4.png);
  }
  .rank5 {
    background-image: url(img/rank5.png);
  }
  .rank6 {
    background-image: url(img/rank6.png);
  }
  .rank7 {
    background-image: url(img/rank7.png);
  }
  .rank8 {
    background-image: url(img/rank8.png);
  }
  .rank9 {
    background-image: url(img/rank9.png);
  }
  .rank10 {
    background-image: url(img/rank10.png);
  }
  .rankbox {
    overflow: hidden;
    padding-top: 20px;
    background-position: center top;
    background-repeat: repeat-x;
    .rankbox-inner {
      overflow: hidden;
    }
    .feature {
      overflow: hidden;
      margin-bottom: 5px;
      padding-bottom: 5px;
      border-bottom: 1px solid #ddd;
      font-size: 18px;
      span {
        font-weight: 700;
      }
    }
    .float-l img, .float-r img {
      margin: 0 20px 0 0;
      padding: 0;
      height: auto;
      max-width: 280px;
    }
    .rankbox-inner p {
      overflow: hidden;
      padding-bottom: 10px;
      color: $textColor;
      text-shadow: 0 0 1px rgba(51, 51, 51, 0.1);
      font-size: 18px;
      line-height: 1.5;
    }
    .rank-table {
      display: none;
      clear: both;
      width: 100%;
      border-right: 1px solid #ddd;
      border-left: 1px solid #ddd;
      th, td {
        padding: 5px 15px;
        border-top: 1px solid #ddd;
        border-right: medium none;
        border-bottom: 1px solid #ddd;
        border-left: medium none;
        vertical-align: middle;
        text-align: left;
        font-size: 15px;
      }
      th {
        background-color: #f5f5f5;
      }
      td {
        color: $textColor;
      }
    }
  }
  .rank {
    .comment {
      margin-top: 20px;
      padding: 5px;
      background-color: #f1f1ee;
      .comment-inner {
        position: relative;
        border: 1px solid #c5d7ed;
        background-color: #eff4fa;
      }
      .comment-tit {
        position: relative;
        top: -5px;
        padding: 10px 5px 0 15px;
        background-image: url(img/comment_bg.png);
        background-position: 10px 12px;
        background-repeat: no-repeat;
        background-size: 35px auto;
            color: #771028;
        text-shadow: 0 0 1px rgba(119, 16, 40, 0.1);
        font-size: 17px;
        span {
          position: relative;
          display: block;
          padding: 10px 10px 6px 40px;
          border-bottom: 1px solid #e6e6e4;
          font-weight: 700;
          font-size: 18px;
          line-height: 1.4;
        }
      }
      p {
        padding: 0 15px 10px;
        font-size: 18px;
        line-height: 1.6;
      }
    }
    .more {
      position: relative;
      overflow: hidden;
      padding: 20px 0 15px;
      text-align: center;
      letter-spacing: -.40em;
      font-size: 0;
      span {
        display: inline-block;
        margin-right: 20px;
        width: 256px;
        height: 53px;
        vertical-align: bottom;
        letter-spacing: normal;
        font-size: 14px;
      }
      a {
        display: block;
        position: relative;
        overflow: hidden;
        height: 53px;
        border: 1px solid #666;
        border-radius: 4px;
        box-shadow: 2px 2px 2px 1px rgba(78, 78, 78, 0.35);
        padding-left: 1em;
        background: rgb(0,255,0);
        background: linear-gradient(to bottom, rgba(0,255,0,1) 0%,rgba(0,229,0,1) 10%,rgba(0,229,0,1) 90%,rgba(0,204,0,1) 100%);
        line-height: 53px;
        color: #fff;
        font-size: 1.1rem;
        font-weight: 900;
        text-decoration: none;
        &::before {
          display: block;
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: .6em;
          width: 1.2em;
          height: 1.2em;
          margin: auto 0;
          background-image: url(img/button_more_icon.png);
          background-size: contain;
          background-repeat: no-repeat;
          }
      }
      span.official {
        margin-right: 0;
        a {
        background: rgb(255,193,102);
        background: linear-gradient(to bottom, rgba(255,193,102,1) 0%,rgba(255,168,38,1) 10%,rgba(255,168,38,1) 90%,rgba(242,159,36,1) 100%);
        &::before {
          background-image: url(img/button_official_icon.png);
          }
        }
      }
    }
  }
}


@media screen and (max-width: 640px) {
  #main {
    .point1, .point2, .point3, .point4, .point5, .point6, .point7, .point8, .point9, .point10 {
      padding-left : 76px;
      background-size: 74px 28px;
    }
  }
}

/*
==================================================
ポイント＆ランキング
==================================================
*/

/* ポイント＆ランキング
==================================================
*/

@media all and (-webkit-min-device-pixel-ratio: 1.5) {
  #main {
    .rank1 {
      background-image: url(img/rank1@2x.png);
    }
    .rank2 {
      background-image: url(img/rank2@2x.png);
    }
    .rank3 {
      background-image: url(img/rank3@2x.png);
    }
    .rank4 {
      background-image: url(img/rank4@2x.png);
    }
    .rank5 {
      background-image: url(img/rank5@2x.png);
    }
    .rank6 {
      background-image: url(img/rank6@2x.png);
    }
    .rank7 {
      background-image: url(img/rank7@2x.png);
    }
    .rank8 {
      background-image: url(img/rank8@2x.png);
    }
    .rank9 {
      background-image: url(img/rank9@2x.png);
    }
    .rank10 {
      background-image: url(img/rank10@2x.png);
    }
    .rank1, .rank2, .rank3, .rank4, .rank5, .rank6, .rank7, .rank8, .rank9, .rank10 {
      background-size: 49px 52px;
    }
  }
}

@media screen and (max-width: 899px) {
  #main {
    .rankbox-inner {
      padding: 10px 0 16px 0;
      line-height: 1.3;
      .float-l{
        float: none;
        p{
          text-align: center;
        }
      }
    }
    .rank .more {
      span {
        display: block;
        margin: 0 auto 20px;
        width: 90%;
        height: 45px;
        vertical-align: bottom;
        letter-spacing: normal;
        font-size: 14px;
        &.official {
          margin: 0 auto;
        }
      }
      a {
        position: relative;
        display: block;
        overflow: hidden;
        margin: 0 auto;
        width: 100%;
        height: 45px;
        line-height: 45px;
      }
      span.official a {
      }
    }
  }
}

@media screen and (max-width: 640px) {
  #main {
    .rank1, .rank2, .rank3, .rank4, .rank5, .rank6, .rank7, .rank8, .rank9, .rank10 {
      font-size: 20px;
    }
    .rankbox {
      .rankbox-inner {
        .float-l, .float-r {
          float: none;
          overflow: hidden;
          padding-bottom: 10px;
          text-align: center;
        }
      }
      .float-l img, .float-r img {
        margin: 0;
        padding: 0;
        height: auto;
        max-width: 200px;
      }
    }
    .rank-inner {
      margin: 0;
      padding: 10px 12px;
    }
    .rank1, .rank2, .rank3, .rank4, .rank5, .rank6, .rank7, .rank8, .rank9, .rank10 {
      line-height: 1.3;
    }
    .rank .comment {
      padding: 5px 3px;
      p {
        padding: 0 10px 10px;
        font-size: 16px;
        line-height: 1.6;
      }
    }
  }
}

/* サイドメニューランキング
==================================================
*/

#menu {
  .rank1, .rank2, .rank3, .rank4, .rank5 {
    margin: 0;
    height: auto;
    border: medium none;
    background-position: left top;
    background-repeat: no-repeat;
    color: $textColor;
    font-weight: 700;
    font-size: 14px;
    line-height: 1.5;
  }
  .rank1, .rank2, .rank3 {
    padding: 18px 0 18px 65px;
  }
  .rank4, .rank5 {
    padding: 10px 0 15px 65px;
  }
  .rank1 {
    background-image: url(img/rank1.png);
  }
  .rank2 {
    background-image: url(img/rank2.png);
  }
  .rank3 {
    background-image: url(img/rank3.png);
  }
  .rank4 {
    background-image: url(img/rank4.png);
  }
  .rank5 {
    background-image: url(img/rank5.png);
  }
}

/*
==================================================
カラム１
==================================================
*/

#column1 {
  background-image: url(img/column_bg.gif);
  background-position: center top;
  background-repeat: no-repeat;
  text-align: center;
  #menu {
    float: none;
    width: auto;
  }
  #main {
    float: none;
    width: auto;
    max-width: 970px;
    margin-left: 0;
    margin-bottom: 10px;
    padding: 0 20px 20px;
  }
  #menu {
    margin-right: 10px;
    .menulist {
      padding: 0;
    }
    .menubox {
      padding-top: 10px;
    }
    ul {
      padding: 5px 10px;
      background: none repeat scroll 0 0 transparent;
      letter-spacing: -.4em;
    }
    li {
      display: inline-block;
      padding: 0 0 0 4px;
      border: none;
      background-image: url(img/menu_bg.png);
      background-position: left 5px;
      background-repeat: no-repeat;
      letter-spacing: normal;
      a {
        display: inline;
        padding: 0;
        background: none repeat scroll 0 0 transparent;
        text-decoration: none;
        font-weight: 400;
      }
    }
    #newEntry li a {
      display: inline;
      padding: 0;
      background: none repeat scroll 0 0 transparent;
      text-decoration: none;
      font-weight: 400;
    }
    li a:hover, #newEntry li a:hover {
      text-decoration: underline;
    }
    .menulist li {
      a, &.l2 a {
        margin: 0 5px;
        padding: 0 5px;
      }
    }
  }
  .menulist #menu li.l3 a {
    margin: 0 5px;
    padding: 0 5px;
  }
  #menu ul {
    &#newEntry li, &#entryarchive li {
      margin: 0 5px;
      padding: 0 5px;
      background-image: none;
    }
  }
  #rmenu ul {
    &#newEntry li, &#entryarchive li {
      margin: 0 5px;
      padding: 0 5px;
      background-image: none;
    }
  }
}

@media screen and (max-width: 899px) {
  #column1 {
    background: none repeat scroll 0 0 transparent;
  }
}

@media screen and (max-width: 640px) {
  #column1 {
    #main {
      margin-left: 0;
      padding-bottom: 0;
    }
    #menu {
      margin-right: 0;
      ul {
        position: relative;
        padding: 0 0 5px;
        letter-spacing: normal;
        &#newEntry {
          position: relative;
          padding: 0 0 10px 0;
          background: none repeat scroll 0 0 transparent;
        }
      }
    }
  }
  #menu ul#entryarchive {
    position: relative;
    padding: 0 0 10px 0;
    background: none repeat scroll 0 0 transparent;
  }
  #rmenu ul {
    &#newEntry, &#entryarchive {
      position: relative;
      padding: 0 0 10px 0;
      background: none repeat scroll 0 0 transparent;
    }
  }
  #column1 {
    #menu .menubox {
      padding-top: 0;
    }
    .menulist ul {
      position: relative;
      background: none repeat scroll 0 0 transparent;
    }
    #menu {
      li {
        position: relative;
        display: block;
        padding: 0;
        border-bottom: 1px solid #e6e6e6;
        background: none repeat scroll 0 0 transparent;
        line-height: 1.5;
      }
      .menulist {
        .l2, .l3 {
          display: none;
        }
      }
      li a {
        display: block;
        padding: 12px 10px 12px 35px;
        background-image: url(img/menu_bg.png);
        background-position: 20px 17px;
        background-repeat: no-repeat;
        color: $textColor;
        text-decoration: none;
        font-size: 14px;
        &:hover {
          color: $mainColor;
          text-decoration: underline;
        }
      }
      .menulist li {
        a, &.l2 a {
          margin: 0;
          padding: 12px 10px 12px 35px;
        }
      }
    }
    .menulist #menu li.l3 a {
      margin: 0;
      padding: 12px 10px 12px 35px;
    }
    #menu ul {
      &#newEntry li, &#entryarchive li {
        margin: 0;
        padding: 15px 10px;
      }
    }
    #rmenu ul {
      &#newEntry li, &#entryarchive li {
        margin: 0;
        padding: 15px 10px;
      }
    }
  }
}

/*
==================================================
カラム２
==================================================
*/

#column2 {
  text-align: center;
}

@media screen and (max-width: 899px) {
  #column2 {
    background: none repeat scroll 0 0 transparent;
  }
}

/*
==================================================
カラム調整
==================================================
*/

#column1 #rmenu, #column2 #rmenu {
  display: none;
}

#column1 {
  #space3, #space4, .bannerbox, #submenu {
    display: none;
  }
}

#column3 {
  #rmenu {
    float: left;
  }
  #layoutbox {
    float: right;
  }
  #main {
    float: left;
  }
}

/*--------------------------------
   SNS
---------------------------------*/

.sns {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  margin: 0 auto 1em;
  padding: 0 ;
  width: 100%;
  list-style: none;
}
.sns li {
  margin: 5px .5rem;
  padding: 0;
  background-image: none;
  list-style-type: none;
}
.sns li a {
  text-decoration: none;
}
.sns li a img {
  width: 50px;
}
.sns li a span {
  display: none;
}
.fa-chevron-down::before {
  content: "";
}
@media screen and (max-width: 899px) {
  ul.sns li::before {
    display: none;
  }
}
.hatena {
  display: none;
}

@media only screen and (min-width: 641px) {
  .sns li a {
    display: block;
    width: 90px;
    height: 40px;
    border-radius: 4px;
  }
  .sns li a img {
    display: none;
  }
  .sns li a span {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    padding-left: 1em;
    width: 100%;
    color: #fff;
    text-align: center;
    font-size: .8em;
    line-height: 40px;
  }
  .sns .fa {
    font-family: "Droid Sans", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", メイリオ, Meiryo, Roboto, Verdana, sans-serif;
  }

  #facebook span::before,#twitter span::before,#googleplus span::before,#hatena span::before,#line span::before,#pocket span::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 8px;
    display: block;
    margin: auto 0;
    width: 1em;
    height: auto;
    color: #fff;
    font-size: 20px;
    font-family: FontAwesome;
  }
  #facebook {
    border-bottom: 2px solid #18274a;
    background-color: #315096;
  }
  #facebook span::before {
    content: "\f09a";
  }
  #twitter {
    border-bottom: 2px solid #38709c;
    background: #55acee;
  }
  #twitter span::before {
    content: "\f099";
  }
  #googleplus {
    border-bottom: 2px solid #8d2d1d;
    background-color: #dd4b39;
  }
  .sns li a#googleplus span {
    padding-left: 1.5em;
  }
  #googleplus span::before {
    content: "\f0d5";
  }
  .hatena {
    display: block;
  }
  #hatena {
    border-bottom: 2px solid #2d558a;
    background-color: #4888db;
  }
  #hatena span::before {
    background: url(img/share_hatena_pc.png) center 12px no-repeat;
    background-size: contain;
    content: "";
  }
  #line {
    border-bottom: 2px solid #1b7510;
    background-color: #2dc81d;
  }
  #line span::before {
    background: url(img/share_line_pc.png) center 11px no-repeat;
    background-size: contain;
    content: "";
  }
  #pocket {
    border-bottom: 2px solid #992938;
    background-color: #ee4056;
  }
  #pocket span::before {
    background: url(img/share_pocket_pc.png) center center no-repeat;
    background-size: contain;
    content: "";
  }
  #pocket a:hover {
    color: #fff;
  }
}
@media only screen and (min-width: 767px) {
  .line {
    display: none;
  }
}
@media only screen and (min-width: 900px) {
  .sns li a {
    width: 110px;
  }
}