@charset "UTF-8";
/*
==================================================
シンプル ブルー
==================================================
*/
/**** Simple Type ******/
/*
==================================================
スタイルの初期化
==================================================
*/
* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }
  *:before, *:after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption {
  outline: 0;
  border: 0;
  background: transparent;
  vertical-align: baseline;
  font-size: 100%; }

blockquote:before, blockquote:after {
  content: '';
  content: none; }

q:before, q:after {
  content: '';
  content: none; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  -webkit-text-size-adjust: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

div, p, h1, h2, h3, h4, h5 {
  display: block;
  text-align: left; }

h1, h2, h3, h4, h5 {
  line-height: 1.4; }

img {
  display: inline-block;
  max-width: 100%;
  border: 0; }

@media screen and (max-width: 899px) {
  img {
    max-width: 100%;
    height: auto;
    vertical-align: top;
    font-size: 0;
    line-height: 0; } }

@media screen and (max-width: 640px) {
  img {
    float: none !important;
    max-width: 100% !important; } }

a {
  margin: 0;
  padding: 0;
  background: transparent;
  vertical-align: baseline;
  font-size: 100%; }

:focus {
  outline: 0; }

ins {
  text-decoration: none; }

del {
  text-decoration: line-through; }

table {
  border-spacing: 0;
  border-collapse: collapse; }

input, select {
  vertical-align: middle; }

figure {
  text-align: center;
  margin: 0 .4rem .8rem; }

figure figcaption {
  padding-top: .4em;
  font-size: .8em;
  text-align: center;
  line-height: 1.2; }

/*
==================================================
 common修正
==================================================
*/
/* エントリーリスト thumbnail */
#entrylist dl > div {
  clear: both; }

/* 文字装飾 */
.ylw {
  padding: 2px;
  font-weight: bold;
  background: linear-gradient(transparent 50%, #ff9 50%); }

.red {
  padding: 2px;
  color: #ff372b;
  font-weight: bold; }

blockquote {
  position: relative;
  padding: 35px 15px 10px 15px;
  box-sizing: border-box;
  font-style: italic;
  background: #f5f5f5;
  color: #777777;
  border-left: 4px solid #9dd4ff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14); }

blockquote:before {
  display: inline-block;
  position: absolute;
  top: 10px;
  left: 15px;
  content: "\f10d";
  font-family: FontAwesome;
  color: #9dd4ff;
  font-size: 28px;
  line-height: 1;
  font-weight: 900; }

blockquote p {
  padding: 0;
  margin: 7px 0;
  line-height: 1.7; }

blockquote cite {
  display: block;
  text-align: right;
  color: #888888;
  font-size: 0.9em; }

/* 矢印 */
.arrow_blue, .arrow_orange, .arrow_black, .arrow_red {
  overflow: hidden;
  margin-top: 3px;
  margin-bottom: 3px;
  padding-left: 25px;
  background-position: left center;
  background-repeat: no-repeat;
  font-weight: bold;
  line-height: 1.6; }

.arrow_link {
  margin-top: 3px;
  margin-bottom: 3px;
  padding-left: 25px;
  background-image: url(img/arrow_orange.gif);
  background-position: left center;
  background-repeat: no-repeat; }

/* 吹き出し・box */
.bln {
  font-size: .95rem; }

.nmlbox, .dottbox, .ylwbox, .graybg {
  margin: 1rem .6rem;
  padding: 1rem; }

.ylwbox {
  background-color: #ffffcc;
  border-color: #003580; }

/* お知らせ */
.alert, .accept, .attention, .thint {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-left: 55px;
  background-position: 8px 3px;
  font-size: 1rem;
  line-height: 1.6; }

/* FAQ */
.question1, .question2 {
  font-size: 1.1rem;
  line-height: 1.3; }

.answer1, .answer2 {
  font-size: 1rem;
  line-height: 1.4; }

/* 口コミ */
.user1, .user2, .user3 {
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 20px 12px 20px 100px;
  min-height: 70px;
  border: 1px solid #c5d7ed;
  border-radius: 3px;
  background-color: #eff4fa;
  background-position: 12px 12px;
  background-repeat: no-repeat;
  background-size: 82px auto;
  font-size: 1rem;
  line-height: 1.6; }

@media screen and (max-width: 640px) {
  .user1, .user2, .user3 {
    padding: 100px 10px 10px;
    background-position: center 10px; }
  .user1.noimage {
    padding: 10px 10px 10px; } }

.user1 {
  background-image: url(img/user1.png); }

.user2 {
  background-image: url(img/user2.png); }

.user3 {
  background-image: url(img/user3.png); }

.noimage {
  padding-left: 25px;
  min-height: 15px;
  background-image: none; }

/*
==================================================
 textstyles修正
==================================================
*/
.txt-border p {
  font-size: 1rem;
  line-height: 30px; }

/*
==================================================
 全サイト共通アイテム
==================================================
*/
/* フォントサイズ */
html {
  font-size: 16px; }

@media screen and (min-width: 641px) {
  html {
    font-size: 17px; } }

@media screen and (min-width: 900px) {
  html {
    font-size: 18px; } }

/* 共通 */
.lbg {
  line-height: 1.6; }

.center {
  margin: 0 auto;
  text-align: center; }

.pc {
  display: none !important; }

.sp {
  display: block !important; }

@media screen and (min-width: 768px) {
  .pc {
    display: block !important; }
  .sp {
    display: none !important; } }

/*
==================================================
基本設定
==================================================
*/
body {
  background-color: #fff;
  color: #333;
  text-align: center;
  letter-spacing: -.001em;
  font-size: 1rem;
  font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック",sans-serif;
  line-height: 1.8; }

h1, h2, h3, h4, h5, .title, dt {
  font-weight: 700; }

.clear {
  clear: both;
  font-size: 1px;
  line-height: 0; }

/* useful
==================================================
*/
ul {
  margin: 0 10px;
  padding: 10px 0; }
  ul li {
    padding: 0 0 0 16px;
    background-image: url(img/li.png);
    background-position: 5px 10px;
    background-repeat: no-repeat; }

ol {
  margin: 0 10px;
  padding: 10px 0 10px 25px;
  list-style-type: decimal; }

/* 動画 */
.youtube {
  text-align: center; }

@media screen and (max-width: 640px) {
  .youtube {
    position: relative;
    padding-top: 56.25%;
    padding-bottom: 30px; }
    .youtube iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; } }

/* リンク
==================================================
*/
a {
  color: #06c;
  transition: all .4s; }
  a:hover {
    color: #0b70fe;
    text-decoration: none;
    opacity: .8; }
    a:hover img {
      background: none !important;
      opacity: .6; }
  a img {
    background: none !important; }

#main a:hover, #menu a:hover, #rmenu a:hover, #pagetop a:hover {
  position: relative;
  top: 1px;
  left: 1px; }

@media screen and (max-width: 640px) {
  #main a:hover, #menu a:hover, #rmenu a:hover, #pagetop a:hover {
    position: static;
    top: 0;
    left: 0; } }

/*
==================================================
レイアウト
==================================================
*/
#wrapper {
  overflow: hidden;
  text-align: center; }

#contents {
  position: relative;
  margin: 0 auto;
  padding: 20px 0 50px;
  width: 1000px;
  text-align: center; }

#layoutbox {
  overflow: hidden;
  padding: 5px 10px; }

#siteNavi {
  margin: 5px 0 8px;
  padding: 5px 20px;
  font-size: 15px; }

@media screen and (max-width: 899px) {
  #contents {
    padding: 15px 10px 50px;
    width: auto; }
  #layoutbox {
    padding: 5px; } }

@media screen and (max-width: 640px) {
  #contents {
    padding: 0 0 50px; }
  #layoutbox {
    padding: 5px 0; }
  #siteNavi {
    margin-bottom: 5px;
    padding: 0 10px;
    font-size: 13px; } }

/* トップへ戻る
==================================================
*/
#pagetop {
  position: fixed;
  right: 50px;
  bottom: 50px;
  z-index: 5;
  display: none;
  clear: both; }
  #pagetop a {
    display: block;
    overflow: hidden;
    width: 50px;
    height: 50px;
    background-image: url(img/pagetop_button.png);
    background-position: left top;
    background-repeat: no-repeat;
    text-indent: 200%;
    white-space: nowrap;
    opacity: .9; }
    #pagetop a:hover {
      opacity: 1; }

#move-page-top {
  display: block;
  cursor: pointer; }

@media screen and (max-width: 640px) {
  #pagetop {
    right: 5px;
    bottom: 5px;
    overflow: hidden; } }

/* 検索
==================================================
*/
#searchbox {
  position: relative;
  margin-left: 0;
  padding: 8px;
  width: 280px;
  height: 48px;
  font-size: 16px;
  text-align: left; }
  #searchbox form {
    position: relative;
    margin: 0;
    padding: 0; }
    #searchbox form::before {
      display: block;
      content: "\f002";
      position: absolute;
      top: 0;
      right: 4px;
      z-index: 1;
      width: 28px;
      height: 28px;
      font-family: FontAwesome;
      font-size: 20px;
      line-height: 28px;
      color: #403829;
      text-align: center; }
  #searchbox input.skwd {
    background-color: #fff;
    border: 1px solid #bbb;
    width: 260px;
    height: auto;
    padding: .4em 28px .4em .5em;
    font-size: 14px;
    cursor: auto; }
  #searchbox input[type="submit"] {
    position: absolute;
    top: 0;
    right: 4px;
    z-index: 2;
    width: 28px;
    height: 28px;
    background-color: transparent;
    border: none;
    vertical-align: middle;
    cursor: pointer; }

@media screen and (max-width: 899px) {
  #searchbox {
    width: 100%; }
  #searchbox input.skwd {
    width: 100%; } }

/* useful
==================================================
*/
.txt-img img {
  margin: 0 8px 8px 8px; }

.center {
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center; }

.float-l {
  float: left; }

.float-r {
  float: right; }

.float-l img, .float-r img {
  margin-bottom: 20px; }

.float-l img {
  margin-right: 20px; }

.float-r img {
  margin-left: 20px; }

.clear, .FloatEnd {
  clear: both;
  height: 0;
  font-size: 1px;
  line-height: 0; }

.label_green,
.label_orange {
  padding: 0 .7em;
  color: #fff;
  font-weight: 700;
  border-radius: 3px; }

.label_green {
  background-color: green; }

.label_orange {
  background-color: darkorange; }

@media screen and (max-width: 640px) {
  .txt-img {
    margin: 10px auto;
    padding: 0 10px;
    text-align: center;
    float: none !important; }
    .txt-img img {
      float: none !important; }
  .txt-img.timg-r, .txt-img.timg-l {
    max-width: 100%; }
  .float-l {
    float: none;
    text-align: center; }
    .float-l p {
      float: none;
      text-align: center; } }

/*
==================================================
カラム
==================================================
*/
/* fit-sidebar */
.fit-sidebar-fixed {
  position: fixed;
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.fit-sidebar-blank {
  z-index: 0;
  visibility: hidden;
  background-color: transparent; }

/* ヘッダー
==================================================
*/
header {
  margin: 0 auto;
  height: auto !important;
  text-align: center; }

#header_sp {
  margin: 0 auto;
  height: auto !important;
  text-align: center; }

#header {
  display: none; }

@media screen and (min-width: 768px) {
  header {
    position: relative;
    width: auto; }
  #header_sp {
    display: none; }
  #header {
    display: block;
    margin: 0 auto;
    max-width: 1000px;
    height: auto !important;
    text-align: center; } }

/* トップ領域
==================================================
*/
*:first-child + html body {
  padding-left: 1px; }

.header__top {
  background-color: #003580; }

#logo {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  line-height: 0; }
  #logo img {
    width: calc(100% - 50px); }

@media screen and (min-width: 768px) {
  #logo img {
    width: 320px; } }

/* トップメニュー
==================================================
*/
#nav {
  display: none; }

/* スマホnavi横スライド形式 */
#overlay {
  display: none;
  position: fixed;
  top: 0;
  z-index: 799;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); }

#navSp {
  margin: 0;
  position: relative;
  top: 0; }
  #navSp #navSp__logo {
    width: calc(100% + 10px);
    margin-left: -10px;
    margin-bottom: 12px;
    background-color: #003580; }
    #navSp #navSp__logo a {
      display: block;
      max-width: calc(100% - 55px); }
  #navSp #topmenubtn {
    position: fixed;
    top: 12px;
    right: 10px;
    z-index: 1000;
    width: 45px;
    height: 45px;
    border: 1px solid #003580;
    border-radius: 4px;
    background: linear-gradient(to bottom, #fff 0%, #eee 100%);
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
    color: #003580;
    font-size: 9px;
    line-height: 26px;
    padding-top: 22px;
    text-align: center;
    cursor: pointer; }
    #navSp #topmenubtn::before {
      border-top: 2px solid #003580;
      border-bottom: 2px solid #003580;
      content: "";
      position: absolute;
      top: 8px;
      left: 5px;
      width: 33px;
      height: 10px; }
    #navSp #topmenubtn::after {
      border-top: 2px solid #003580;
      content: "";
      position: absolute;
      top: 23px;
      left: 5px;
      width: 33px;
      height: 0; }

#navSp__box {
  position: fixed;
  top: 0;
  right: -300px;
  z-index: 800;
  padding: 10px 1px 10px 10px;
  width: 300px;
  height: 100%;
  overflow-y: scroll;
  background-color: #F5F5F5;
  box-shadow: -5px 0px 5px rgba(0, 0, 0, 0.2);
  transition: transform .4s ease-out;
  transform: translateX(0); }
  #navSp__box.menuon {
    transform: translateX(-100%); }
  #navSp__box .navSp__menu ul {
    margin-left: 0;
    margin-right: 0; }
  #navSp__box .navSp__menu li {
    display: block;
    background-image: none;
    border-top: 1px solid #cfd4da;
    border-bottom: 1px solid #f3f9ff;
    padding-left: 0;
    font-weight: 700;
    text-align: left; }
    #navSp__box .navSp__menu li:last-child {
      border-bottom: 2px solid #cfd4da; }
    #navSp__box .navSp__menu li a {
      display: block;
      position: relative;
      overflow: visible;
      padding: .8em 1em .7em 10px;
      height: auto;
      border-bottom: none;
      color: #333;
      text-align: left;
      line-height: 1.2;
      text-decoration: none; }
      #navSp__box .navSp__menu li a:before {
        content: '';
        position: absolute;
        top: 50%;
        right: 8px;
        margin-top: -2px;
        width: 5px;
        height: 5px;
        border-top: 1px solid #003580;
        border-right: 1px solid #003580;
        border-bottom: none;
        border-left: none;
        background-color: transparent;
        transform: rotate(45deg); }
  #navSp__box .navSp__menu li.l2 {
    padding-left: 10px;
    font-weight: 400; }

@media screen and (min-width: 768px) {
  #nav {
    display: block;
    width: 100%;
    background-color: #dde4ee; }
  #navSp, #overlay, #topmenubtn {
    display: none; }
  #topmenu {
    max-width: 1000px;
    margin: 0 auto; }
  #topmenu ul, #topmenu li {
    background-image: none;
    list-style-type: none;
    list-style-image: none; }
  #topmenu ul {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding: 2px 0 0;
    height: auto;
    font-weight: 700; }
  #topmenu li {
    flex-basis: 20%;
    padding: 0;
    border-right: 1px solid #c9c9c9;
    background: linear-gradient(to bottom, #fff 0%, #f6f6f6 47%, #ededed 100%);
    box-shadow: 0 -1px 0 white inset;
    cursor: pointer; }
    #topmenu li a {
      display: block;
      overflow: hidden;
      padding: 0 2px;
      height: 49px;
      border-bottom: 2px solid #1f477f;
      color: #1f477f;
      list-style-image: none;
      text-align: center;
      text-decoration: none;
      font-size: .85em;
      line-height: 47px; }
      #topmenu li a:hover {
        border-bottom: 2px solid #0b70fe;
        background: linear-gradient(to bottom, #fff 1%, #fffdf8 50%, #fff6e6 51%, #e6e1d9 99%);
        color: #0b70fe;
        opacity: .8; } }

/*
@media screen and (max-width: 899px) {
  #topmenu {
    ul {
      display: table;
      clear: both;
      margin: 0;
      width: 100%;
      background: none repeat scroll 0 0 transparent;
    }
    li {
      display: table-cell;
      float: none;
      margin: 0;
      padding: 0;
      width: auto;
      &:before {
        position: static;
        top: auto;
        left: auto;
        clear: both;
        width: auto;
        height: auto;
        background-color: transparent;
        content: "";
      }
      a {
        position: relative;
      }
    }
  }
}
*/
/*
==================================================
メインコンテンツ
==================================================
*/
/* 
==================================================
*/
#main {
  float: left;
  overflow: hidden;
  width: 720px;
  background-color: #fff;
  box-shadow: 2px 2px 3px #cdcdcd,-1px -1px 3px #cdcdcd;
  word-wrap: break-word; }
  #main .text {
    overflow: hidden;
    margin: 10px 15px 20px;
    padding: 10px; }

@media screen and (max-width: 899px) {
  #main {
    width: 72.2222222222%; } }

@media screen and (max-width: 640px) {
  #main {
    float: none;
    width: 100%; } }

#text1, #text2, #text3, #text4, #text5, #text6, #text7, #text8, #text9, #text10, #space1, #space2 {
  overflow: hidden;
  margin: 10px 15px 20px;
  padding: 10px; }

@media screen and (max-width: 640px) {
  #main .text, #text1, #text2, #text3, #text4, #text5, #text6, #text7, #text8, #text9, #text10, #space1, #space2 {
    margin: 10px 5px 15px;
    padding: 10px 5px; } }

/* 見出し
==================================================
*/
#main h1, #main h2 {
  position: relative;
  display: block;
  margin-bottom: 5px;
  padding: 10px 5px 8px 30px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  background: linear-gradient(to bottom, #fff 0%, #f0f0f0 100%);
  box-shadow: 0 -1px 0 white inset;
  color: #333;
  text-shadow: 0 0 1px rgba(255, 255, 255, 0.3);
  font-size: 20px; }

#main h1::after, #main h2::after {
  position: absolute;
  top: .5em;
  left: .5em;
  z-index: 2;
  width: 6px;
  height: calc(100% - 1em);
  border-radius: 4px;
  background-color: #003580;
  content: ""; }

#main .text h2 {
  margin: 0 0 5px -10px; }

#text1 h2, #text2 h2, #text3 h2, #text4 h2, #text5 h2, #text6 h2, #text7 h2, #text8 h2, #text9 h2, #text10 h2, #space1 h3, #space2 h3 {
  margin: 0 0 5px -10px; }

#main h3 {
  position: relative;
  margin: 10px auto 10px;
  padding: .35em 0 .25em .5em;
  border-left: 6px solid #003580;
  color: #333;
  text-shadow: 0 0 1px rgba(78, 123, 160, 0.3);
  font-size: 20px;
  line-height: 1.4; }
  #main h3::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    border-bottom: 1px solid #ccc;
    content: ""; }

#main h4 {
  margin: 3px auto;
  padding: 10px;
  padding-left: 28px;
  background-image: url(img/h5.png);
  background-position: left center;
  background-repeat: no-repeat;
  color: #333;
  text-shadow: 0 0 1px rgba(51, 51, 51, 0.1);
  font-size: 20px;
  line-height: 150%; }

#main h5 {
  margin: 3px auto;
  padding-left: 22px;
  background-image: url(img/h6.png);
  background-position: left 1px;
  background-repeat: no-repeat;
  color: #333;
  text-shadow: 0 0 1px rgba(51, 51, 51, 0.1);
  font-size: 16px;
  line-height: 140%; }

#main .atn02 {
  overflow: hidden;
  margin: 20px 0 10px;
  padding: 20px 20px 20px 50px;
  background-color: #f2f2f2;
  background-image: url(img/atn_02.gif);
  background-position: 14px 14px;
  background-repeat: no-repeat; }
  #main .atn02 span {
    padding-bottom: 3px;
    border-bottom: 1px dashed #c2c2c2;
    color: #4d4d4d;
    font-weight: bold; }

@media all and (-webkit-min-device-pixel-ratio: 1.5) {
  #main .atn02 {
    background-image: url(img/atn_02@2x.gif);
    background-size: 22px 32px; } }

@media screen and (max-width: 640px) {
  #main h1, #main h2 {
    margin: 0;
    padding: 10px 10px 8px 28px; }
  #main .text h2 {
    margin: 20px -10px 10px; }
  #main h3 {
    padding: 3px 8px 0 10px; }
  #text1 h2, #text2 h2, #text3 h2, #text4 h2, #text5 h2, #text6 h2, #text7 h2, #text8 h2, #text9 h2, #text10 h2, #space1 h3, #space2 h3 {
    margin: 20px -10px 10px; } }

/*
==================================================
サイドメニュー
==================================================
*/
/* サイドメニュー
==================================================
*/
#menu {
  float: right;
  overflow: hidden;
  width: 230px; }
  #menu img {
    height: auto;
    max-width: 100%; }
  #menu ul#newEntry, #menu ul#entryarchive {
    padding: 0 0 5px 0;
    background-image: url(img/menubox_bg.gif);
    background-position: center bottom;
    background-repeat: repeat-x; }

#rmenu ul#newEntry, #rmenu ul#entryarchive {
  padding: 0 0 5px 0;
  background-image: url(img/menubox_bg.gif);
  background-position: center bottom;
  background-repeat: repeat-x; }

#menu .rankbox {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e6e6e6;
  font-size: 12px; }
  #menu .rankbox img {
    width: 185px; }

.menubox {
  margin-bottom: 25px; }

.menulist {
  overflow: hidden;
  margin-bottom: 0 !important; }

#menu .title, #rmenu .title, #menu h4, #rmenu h4 {
  margin: 0 3px;
  border-top: 2px solid #003580;
  border-bottom: 1px solid #ccc;
  background: linear-gradient(to bottom, #fff 0%, #f0f0f0 100%);
  box-shadow: 2px 0 3px #cdcdcd,-1px -1px 3px #cdcdcd; }

#menu .title span, #rmenu .title span, #menu h4 span, #rmenu h4 span {
  display: block;
  padding: 14px 8px 14px 8px;
  color: #252525;
  text-align: center;
  text-shadow: 0 0 1px rgba(255, 255, 255, 0.3);
  font-size: .94rem;
  line-height: 1.3; }

.menulist ul {
  margin: 0 3px 25px;
  padding: 0 0 5px 0;
  background-color: #fff;
  box-shadow: 2px 2px 3px #cdcdcd,-1px -1px 3px #cdcdcd; }

.menulist li {
  position: relative;
  padding: 0;
  border-bottom: 1px solid #e6e6e6;
  background: none repeat scroll 0 0 transparent;
  line-height: 110%; }
  .menulist li a {
    display: block;
    padding: 12px 10px 12px 25px;
    background-image: url(img/menu_bg.png);
    background-position: 8px 14px;
    background-size: 12px 12px;
    background-repeat: no-repeat;
    color: #333;
    text-decoration: none;
    font-weight: bold;
    font-size: 15px; }
    .menulist li a:hover {
      background-color: #fff5db;
      color: #0b70fe;
      opacity: .8;
      transition: all .4s; }

.menulist .l2 a, .menulist .l3 a {
  padding-right: 8px;
  padding-left: 25px;
  background-image: url(img/menu_bg3.png);
  background-position: 12px 18px;
  background-size: auto;
  font-weight: normal; }

@media screen and (max-width: 899px) {
  #menu {
    width: 24.4444444444%; }
  #column2 .menulist li a {
    position: relative; }
  .menulist .l2 a, .menulist .l3 a {
    position: relative; } }

@media screen and (max-width: 640px) {
  #menu {
    float: none;
    width: 100%; }
    #menu .title {
      margin-left: 0; }
  #rmenu .title, #menu h4, #rmenu h4 {
    margin-left: 0; }
  #menu .title span, #rmenu .title span, #menu h4 span, #rmenu h4 span {
    font-size: 18px; }
  .menulist ul {
    margin-bottom: 5px; }
  .menulist .l2, .menulist .l3 {
    display: none; }
  .menubox {
    margin-bottom: 0; }
  #menu .title span, #rmenu .title span, #menu h4 span, #rmenu h4 span {
    border-top: 1px solid #e6e6e6;
    border-right: none;
    border-bottom: 1px solid #e6e6e6;
    border-left: none; } }

/* バナー
==================================================
*/
.bannerbox {
  position: relative;
  margin-bottom: 10px;
  border-radius: 6px;
  background-color: #fff; }
  .bannerbox ul {
    padding: 5px; }
  .bannerbox li {
    margin-bottom: 5px;
    padding: 0;
    background: none repeat scroll 0 0 transparent; }
    .bannerbox li a {
      font-size: 15px; }
      .bannerbox li a img {
        vertical-align: bottom; }

@media screen and (max-width: 899px) {
  .bannerbox li:before {
    position: static;
    top: auto;
    left: auto;
    width: auto;
    height: auto;
    background-color: transparent;
    content: ""; }
  .menulist li:before {
    background-color: transparent; } }

@media screen and (max-width: 640px) {
  .bannerbox li {
    margin-bottom: 10px;
    text-align: center; } }

/* 最新記事
==================================================
*/
ul#newEntry {
  margin: 0 3px 25px;
  background-color: #fff;
  box-shadow: 2px 2px 3px #cdcdcd,-1px -1px 3px #cdcdcd; }
  ul#newEntry li {
    padding: 0;
    border-bottom: 1px solid #e6e6e6;
    background: none;
    font-size: 14px; }

ul#entryarchive li {
  padding: 0;
  border-bottom: 1px solid #e6e6e6;
  background: none;
  font-size: 14px;
  line-height: 1.6; }

ul#newEntry li a, ul#entryarchive li a {
  display: block;
  padding: 12px 10px 12px 25px;
  background-image: url(img/menu_bg3.png);
  background-position: 12px 20px;
  background-repeat: no-repeat;
  color: #333;
  text-decoration: none;
  font-weight: normal; }

ul#newEntry li a:hover, ul#entryarchive li a:hover {
  background-color: #fff5da;
  color: #0b70fe;
  opacity: .8;
  filter: alpha(opacity=80);
  transition: all .4s; }

@media screen and (max-width: 899px) {
  ul#newEntry li:before, ul#entryarchive li:before {
    position: static;
    top: auto;
    left: auto;
    width: auto;
    height: auto;
    background-color: transparent;
    content: ""; } }

/*
==================================================
フッター(幅広)
==================================================
*/
footer {
  margin-top: -50px;
  border-top: 3px solid #dde4ee;
  background-color: #003580; }
  footer .inner {
    overflow: hidden;
    color: #fff;
    font-size: .72rem;
    line-height: 1.4; }
  footer a {
    color: #fff; }

.ftBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.ftBox_l, .ftBox_c, .ftBox_r {
  width: 100%; }

.ftBox_r img {
  display: block;
  margin: 0 auto; }

.ftBox_r p {
  position: relative;
  overflow: hidden;
  word-wrap: break-word;
  color: ghostwhite;
  font-size: 12px;
  line-height: 1.6;
  padding: 10px 10px 5px;
  text-align: center; }

.ftBox_r #footsub {
  margin: .6rem auto 0;
  height: auto;
  max-width: 900px;
  font-size: 12px;
  letter-spacing: -.40em;
  line-height: 1.6;
  text-align: center; }
  .ftBox_r #footsub span {
    display: inline-block;
    overflow: hidden; }
  .ftBox_r #footsub a {
    border-left: none;
    font-size: .67rem;
    letter-spacing: normal;
    line-height: 1.4;
    margin-left: -1px;
    padding: 0 10px;
    color: ghostwhite;
    text-decoration: none; }
    .ftBox_r #footsub a:first-child {
      border-left: none; }

.footermenu {
  position: static;
  margin: 0;
  padding: 0;
  width: auto; }
  .footermenu li {
    background-image: none;
    border-bottom: 1px solid #ddd; }
    .footermenu li a {
      padding: 5px 10px 5px 0px;
      border-left: none;
      font-size: .88rem;
      line-height: 1.2;
      letter-spacing: normal;
      text-decoration: none; }
  .footermenu span {
    display: block;
    margin: 0;
    border-bottom: 1px solid #dde4ee; }
    .footermenu span ::before {
      position: absolute;
      top: 0;
      bottom: 0;
      right: .5em;
      margin: auto 0;
      content: '';
      display: block;
      width: .6em;
      height: .6em;
      border-top: 1px solid #003580;
      border-right: 1px solid #003580;
      transform: rotate(45deg); }
  .footermenu a {
    position: relative;
    display: block;
    padding: 12px 10px 12px 24px;
    border-right: none;
    background-position: 8px center;
    text-decoration: none;
    font-size: 15px; }

.copyright {
  display: block;
  padding: 20px 0;
  background-color: #003580;
  color: #fff;
  text-align: center;
  font-size: 14px;
  line-height: 28px; }

@media screen and (min-width: 768px) {
  footer {
    margin: 0 auto; }
    footer .inner {
      margin: 0 auto;
      padding: 15px 10px 5px;
      width: 970px;
      max-width: 100%;
      text-align: center; }
  .ftBox_l, .ftBox_c, .ftBox_r {
    width: 32%;
    text-align: left; }
  .footermenu li {
    position: relative;
    margin-bottom: .3em;
    border-bottom: none; }
    .footermenu li::before {
      position: absolute;
      content: "\30FB";
      top: .4em;
      left: 0;
      color: #fff; }
    .footermenu li a:hover {
      text-decoration: underline; }
  .ftBox_r p {
    text-align: left; }
  .ftBox_r #footsub a:hover {
    text-decoration: underline; } }

@media screen and (min-width: 1000px) {
  footer .inner {
    padding-top: 15px;
    text-align: left; } }

/*
==================================================
最新情報
==================================================
*/
#whatsnew {
  position: relative;
  margin-bottom: 20px; }
  #whatsnew .title {
    position: relative;
    margin-bottom: 5px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    background: linear-gradient(to bottom, #fff 0%, #f0f0f0 100%);
    box-shadow: 0 -1px 0 white inset; }
    #whatsnew .title::after {
      position: absolute;
      top: .5em;
      left: .5em;
      z-index: 2;
      width: 6px;
      height: calc(100% - 1em);
      border-radius: 4px;
      background-color: #003580;
      content: ""; }
    #whatsnew .title span {
      display: block;
      padding: 10px 5px 8px 30px;
      color: #333;
      text-shadow: 0 0 1px rgba(255, 255, 255, 0.3);
      font-size: 18px; }
  #whatsnew ul {
    padding: 0; }
    #whatsnew ul li {
      position: relative;
      padding: 10px 15px;
      border-bottom: 1px dashed #ccc;
      background: none repeat scroll 0 0 transparent;
      font-size: 16px; }
      #whatsnew ul li span {
        padding-right: 10px;
        color: #008b8b; }

@media screen and (max-width: 899px) {
  #whatsnew .title {
    position: relative; }
  #whatsnew ul li:before {
    background-color: transparent; } }

@media screen and (max-width: 640px) {
  #whatsnew {
    margin-bottom: 0;
    padding: 10px 0 0;
    background-color: #fbfbfb; }
    #whatsnew .title {
      margin-right: 0;
      border-bottom: none; }
    #whatsnew ul {
      border-bottom: 1px solid #e6e6e6;
      background-color: #fff; }
      #whatsnew ul:last-child {
        border-bottom: none; }
      #whatsnew ul li:last-child {
        border-bottom: none; }
      #whatsnew ul li span {
        display: block;
        padding-right: 0;
        color: #666; } }

/*
==================================================
カテゴリーリスト
==================================================
*/
#categorylist {
  padding: 10px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap; }
  #categorylist .catbox {
    position: relative;
    overflow: hidden;
    width: 48%;
    margin: 0 1% 15px;
    border: 1px solid #003580;
    border-radius: 4px;
    background-color: #fafcff;
    font-size: 15px; }
  #categorylist .thumb {
    position: relative;
    overflow: hidden;
    margin: 20px auto 0;
    width: auto;
    height: 150px; }
    #categorylist .thumb img {
      position: absolute;
      right: -100%;
      left: -100%;
      margin: auto;
      width: auto;
      max-width: inherit;
      height: 150px; }
  #categorylist .body {
    overflow: hidden; }
  #categorylist .title {
    overflow: hidden;
    margin-bottom: 10px;
    padding: 10px 1em 2px;
    border-bottom: 1px dotted #003580;
    font-weight: 700; }
    #categorylist .title a {
      display: block;
      padding: 6px 0 3px 1.5em;
      background-image: url(img/category_title.png);
      background-position: left .5rem;
      background-size: 1.2em auto;
      background-repeat: no-repeat;
      color: #06c;
      text-decoration: none;
      font-size: 1rem;
      line-height: 1.4; }
  #categorylist p {
    padding: 0 15px;
    font-size: 14px;
    line-height: 1.5; }
  #categorylist .more {
    float: right;
    margin: 10px 1px 1px 0;
    padding: 0 15px 15px 0;
    height: 30px; }
    #categorylist .more a {
      display: block;
      padding-right: 19px;
      background-image: url(img/button_more2.png);
      background-position: right center;
      background-repeat: no-repeat;
      color: #06c; }

#pagelist {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  text-align: center; }
  #pagelist ul {
    padding: 0;
    letter-spacing: -.4em; }
  #pagelist li {
    display: inline-block;
    margin: 0 5px 10px;
    padding: 0;
    background: none repeat scroll 0 0 transparent;
    list-style-type: none;
    letter-spacing: normal; }
  #pagelist a {
    display: inline-block;
    padding: 6px 15px;
    border: 1px solid #e6e6e6;
    background-color: #fbfbfb;
    color: #333;
    text-align: center;
    text-decoration: none; }
    #pagelist a:hover {
      background-color: #333;
      color: #fff; }

@media screen and (max-width: 899px) {
  #categorylist .more a {
    position: relative;
    display: block;
    overflow: hidden;
    height: 26px;
    background-position: right 4px;
    text-align: center;
    text-indent: inherit;
    font-size: 15px;
    line-height: 24px; }
    #categorylist .more a:before {
      position: absolute;
      top: 50%;
      right: 16px;
      margin-top: -2px;
      width: 4px;
      height: 4px;
      border-top: 1px solid #fff;
      border-right: 1px solid #fff;
      border-bottom: none;
      border-left: none;
      content: "";
      transform: rotate(45deg); }
  #pagelist li:before {
    position: static;
    top: auto;
    left: auto;
    width: auto;
    height: auto;
    background-color: transparent;
    content: ""; } }

@media screen and (max-width: 640px) {
  #categorylist {
    -ms-flex-direction: column;
    flex-direction: column; }
    #categorylist .catbox {
      width: 100%;
      padding: 15px;
      margin: 0 0 30px; }
    #categorylist .thumb {
      float: none;
      margin: 0 auto 10px;
      height: 120px; }
      #categorylist .thumb img {
        position: static;
        display: block;
        margin: 0 auto;
        width: auto;
        height: 120px; }
    #categorylist .title {
      padding: 5px 0; }
      #categorylist .title a {
        padding: 4px 4px 0 22px; }
    #categorylist p {
      font-size: 13px;
      line-height: 1.5;
      padding: 0; } }

/*
==================================================
エントリーリスト
==================================================
*/
#entrylist {
  margin: 50px 15px 20px; }
  #entrylist .inner {
    overflow: hidden;
    text-align: left; }
  #entrylist h2 {
    position: relative;
    margin: 15px -15px 10px -15px;
    padding: 10px 10px 8px 30px;
    border: 1px solid #ccc;
    border-top: 3px solid #ddd;
    background: linear-gradient(to bottom, #fff 0%, #f0f0f0 100%);
    box-shadow: 0 1px 0 white inset, -5px 0px 5px white;
    color: #333;
    text-shadow: 1px 1px 0 #fff;
    font-size: 18px; }
    #entrylist h2::after {
      position: absolute;
      top: 1em;
      left: .5em;
      z-index: 2;
      width: 12px;
      height: 12px;
      background-color: #003580;
      content: "";
      transform: rotate(45deg); }
  #entrylist dl {
    position: relative;
    top: -1px;
    overflow: hidden;
    border: 1px solid #003580;
    border-radius: 4px;
    background-color: #fafcff; }
  #entrylist dt {
    position: relative;
    margin: 0 10px;
    padding: 15px 5px;
    border-top: 1px dotted #ccc; }
    #entrylist dt:first-child {
      border-top: none; }
    #entrylist dt .entry_title a {
      padding: 1px 0 1px 20px;
      background-image: url(img/entry_title.png);
      background-position: left center;
      background-repeat: no-repeat;
      font-weight: 700;
      font-size: .88rem;
      line-height: 1.4; }
  #entrylist dd {
    margin-top: -10px;
    padding: 0 10px 15px 25px;
    font-size: 14px;
    line-height: 1.6; }

#searchresult {
  overflow: hidden;
  margin-bottom: 20px;
  padding: 10px; }

#relNavigate {
  margin-bottom: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center; }
  #relNavigate a {
    margin-right: 10px;
    margin-left: 10px;
    font-weight: 700;
    font-size: 14px; }

@media screen and (max-width: 640px) {
  #entrylist {
    margin: 0;
    padding: 0; }
    #entrylist h2 {
      margin: 10px 0 10px 0;
      font-size: 18px; }
    #entrylist dl {
      top: 0;
      margin: 5px 5px 10px;
      padding: 0 5px; }
    #entrylist dt {
      padding: 15px 5px; }
      #entrylist dt:first-child {
        border-top: none; }
      #entrylist dt a {
        font-size: 15px; }
        #entrylist dt a:hover {
          position: relative; }
    #entrylist dd {
      padding: 0 5px 15px 20px;
      font-size: 14px;
      line-height: 1.6; }
  #relNavigate a {
    position: relative;
    z-index: 0;
    display: inline-block;
    width: 35%;
    height: 40px;
    background-color: #fbfbfb;
    color: #333;
    text-decoration: none;
    line-height: 40px; }
    #relNavigate a:hover {
      position: relative; }
    #relNavigate a:first-child {
      border-top: 1px solid #e6e6e6;
      border-right: 1px solid #e6e6e6;
      border-bottom: 1px solid #e6e6e6; }
    #relNavigate a:last-child {
      border-top: 1px solid #e6e6e6;
      border-bottom: 1px solid #e6e6e6;
      border-left: 1px solid #e6e6e6; }
    #relNavigate a:before {
      position: absolute;
      top: 5px;
      z-index: -1;
      width: 29px;
      height: 29px;
      background-color: #fbfbfb;
      content: "";
      transform: rotate(45deg); }
    #relNavigate a:first-child:before {
      left: -15px;
      border-bottom: 1px solid #e6e6e6;
      border-left: 1px solid #e6e6e6; }
    #relNavigate a:last-child:before {
      right: -15px;
      border-top: 1px solid #e6e6e6;
      border-right: 1px solid #e6e6e6; } }

/*
==================================================
サイトマップ
==================================================
*/
#sitemaps ul {
  margin: 0 0 20px 10px;
  padding: 5px 10px; }

#sitemaps li {
  padding: 0;
  background-image: none;
  line-height: 0; }

#sitemaps .l1 {
  margin-top: 10px; }

#sitemaps .l2, #sitemaps .l3 {
  margin-top: 5px; }

#sitemaps li a {
  display: inline-block;
  padding-left: 16px;
  background-image: url(img/menu_bg.png);
  background-position: left center;
  background-repeat: no-repeat;
  color: #333;
  list-style-type: none;
  list-style-image: none;
  text-decoration: none;
  line-height: 1.8; }
  #sitemaps li a:hover {
    color: #0b70fe;
    text-decoration: underline; }

#sitemaps .l1 a {
  font-weight: 700;
  font-size: 16px; }

#sitemaps .l2 a {
  margin-left: 25px;
  background-image: url(img/menu_bg3.png);
  font-size: 14px; }

#sitemaps .l3 a {
  margin-left: 45px;
  background-image: url(img/menu_bg3.png);
  background-position: left 6px;
  font-size: 13px; }

@media screen and (max-width: 640px) {
  #sitemaps ul {
    padding: 0 10px; }
  #sitemaps li {
    border-bottom: 1px solid #ddd; }
  #sitemaps .l1, #sitemaps .l2, #sitemaps .l3 {
    margin-top: 0; }
  #sitemaps li a {
    display: block;
    padding: 12px 10px 12px 25px;
    text-decoration: none; }
  #sitemaps .l1 a {
    background-position: 10px 18px;
    line-height: 1.5; }
  #sitemaps .l2 a {
    margin-left: 0;
    padding: 12px 40px;
    background-position: 25px 20px; }
  #sitemaps .l3 a {
    padding: 10px 15px;
    background-position: 2px 16px;
    font-size: 13px; }
  #sitemaps li a:before {
    top: 20px;
    left: 20px; }
  #sitemaps .l2 a:before {
    top: 50%;
    right: 20px;
    left: auto;
    margin-top: -3px; }
  #sitemaps .l3 a:before {
    left: 50px; } }

/*
==================================================
フリースペース
==================================================
*/
#space1, #space2 {
  margin-bottom: 25px; }

#space3, #space4, #space5, #space6 {
  padding: 10px;
  line-height: 1.4; }

.grayline {
  margin: 10px;
  border: 1px solid #ccc; }

.graybg {
  margin: 10px; }

#main .grayline {
  margin: 10px;
  margin-bottom: 15px;
  padding: 10px; }

#menu .grayline {
  margin-bottom: 15px;
  padding: 8px;
  font-size: 90%; }

/*
==================================================
検索結果
==================================================
*/
#searchresult dt {
  padding: 5px 20px;
  font-size: 16px; }

#searchresult dd {
  margin: 0 0 10px;
  padding: 0 20px 15px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #e6e6e6;
  line-height: 1.6; }

#searchresult b {
  color: #06c; }

@media screen and (max-width: 640px) {
  #searchresult dt {
    padding: 15px 10px 5px; }
  #searchresult dd {
    margin: 0 0 0;
    padding: 0 10px 15px; } }

/*
==================================================
リンクリスト
==================================================
*/
#linklist {
  overflow: hidden;
  margin-bottom: 20px;
  padding: 10px; }
  #linklist dt {
    padding: 5px 0;
    font-size: 14px; }
  #linklist dd {
    margin: 0 0 10px;
    padding-bottom: 15px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #e6e6e6;
    line-height: 1.4; }

/*
==================================================
ポイント
==================================================
*/
#main .point1, #main .point2, #main .point3, #main .point4, #main .point5, #main .point6, #main .point7, #main .point8, #main .point9, #main .point10 {
  display: inline-block;
  margin-top: 15px;
  margin-bottom: 3px;
  padding: 3px 5px 8px 100px;
  height: auto;
  background-position: left 3px;
  background-repeat: no-repeat;
  background-size: 90px auto;
  color: #333;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.4; }

#main .point1 a, #main .point2 a, #main .point3 a, #main .point4 a, #main .point5 a, #main .point6 a, #main .point7 a, #main .point8 a, #main .point9 a, #main .point10 a {
  text-decoration: none; }

#main .point1 {
  background-image: url(img/point1.png); }

#main .point2 {
  background-image: url(img/point2.png); }

#main .point3 {
  background-image: url(img/point3.png); }

#main .point4 {
  background-image: url(img/point4.png); }

#main .point5 {
  background-image: url(img/point5.png); }

#main .point6 {
  background-image: url(img/point6.png); }

#main .point7 {
  background-image: url(img/point7.png); }

#main .point8 {
  background-image: url(img/point8.png); }

#main .point9 {
  background-image: url(img/point9.png); }

#main .point10 {
  background-image: url(img/point10.png); }

#main .point1, #main .point2, #main .point3, #main .point4, #main .point5, #main .point6, #main .point7, #main .point8, #main .point9, #main .point10 {
  border: none; }

#main .rank1, #main .rank2, #main .rank3, #main .rank4, #main .rank5, #main .rank6, #main .rank7, #main .rank8, #main .rank9, #main .rank10 {
  height: auto;
  border: none; }

#main .rank {
  position: relative;
  margin-bottom: 20px; }

#main .rank-container {
  position: relative;
  padding: 5px;
  background-color: #f1f1ee; }

#main .rank-inner {
  overflow: hidden;
  padding: 10px 20px 16px 20px;
  border: 1px solid #e6e6e4;
  background-color: #fdfdfd; }

#main .rank1, #main .rank2, #main .rank3, #main .rank4, #main .rank5, #main .rank6, #main .rank7, #main .rank8, #main .rank9, #main .rank10 {
  margin-bottom: 6px;
  padding: 18px 0 0 60px;
  height: auto;
  min-height: 72px;
  border: medium none;
  background-position: left 13px;
  background-size: 56px;
  background-repeat: no-repeat;
  color: navy;
  text-shadow: 0 0 1px rgba(0, 102, 204, 0.1);
  font-size: 24px;
  line-height: 1.4; }

#main .rank .rank1, #main .rank .rank2, #main .rank .rank3, #main .rank .rank4, #main .rank .rank5, #main .rank .rank6, #main .rank .rank7, #main .rank .rank8, #main .rank .rank9, #main .rank .rank10 {
  margin: 0 10px 10px 0; }

#main .rank1 {
  background-image: url(img/rank1.png); }

#main .rank2 {
  background-image: url(img/rank2.png); }

#main .rank3 {
  background-image: url(img/rank3.png); }

#main .rank4 {
  background-image: url(img/rank4.png); }

#main .rank5 {
  background-image: url(img/rank5.png); }

#main .rank6 {
  background-image: url(img/rank6.png); }

#main .rank7 {
  background-image: url(img/rank7.png); }

#main .rank8 {
  background-image: url(img/rank8.png); }

#main .rank9 {
  background-image: url(img/rank9.png); }

#main .rank10 {
  background-image: url(img/rank10.png); }

#main .rankbox {
  overflow: hidden;
  padding-top: 20px;
  background-position: center top;
  background-repeat: repeat-x; }
  #main .rankbox .rankbox-inner {
    overflow: hidden; }
  #main .rankbox .feature {
    overflow: hidden;
    margin-bottom: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #ddd;
    font-size: 18px; }
    #main .rankbox .feature span {
      font-weight: 700; }
  #main .rankbox .float-l img, #main .rankbox .float-r img {
    margin: 0 20px 0 0;
    padding: 0;
    height: auto;
    max-width: 280px; }
  #main .rankbox .rankbox-inner p {
    overflow: hidden;
    padding-bottom: 10px;
    color: #333;
    text-shadow: 0 0 1px rgba(51, 51, 51, 0.1);
    font-size: 18px;
    line-height: 1.5; }
  #main .rankbox .rank-table {
    display: none;
    clear: both;
    width: 100%;
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd; }
    #main .rankbox .rank-table th, #main .rankbox .rank-table td {
      padding: 5px 15px;
      border-top: 1px solid #ddd;
      border-right: medium none;
      border-bottom: 1px solid #ddd;
      border-left: medium none;
      vertical-align: middle;
      text-align: left;
      font-size: 15px; }
    #main .rankbox .rank-table th {
      background-color: #f5f5f5; }
    #main .rankbox .rank-table td {
      color: #333; }

#main .rank .comment {
  margin-top: 20px;
  padding: 5px;
  background-color: #f1f1ee; }
  #main .rank .comment .comment-inner {
    position: relative;
    border: 1px solid #c5d7ed;
    background-color: #eff4fa; }
  #main .rank .comment .comment-tit {
    position: relative;
    top: -5px;
    padding: 10px 5px 0 15px;
    background-image: url(img/comment_bg.png);
    background-position: 10px 12px;
    background-repeat: no-repeat;
    background-size: 35px auto;
    color: #771028;
    text-shadow: 0 0 1px rgba(119, 16, 40, 0.1);
    font-size: 17px; }
    #main .rank .comment .comment-tit span {
      position: relative;
      display: block;
      padding: 10px 10px 6px 40px;
      border-bottom: 1px solid #e6e6e4;
      font-weight: 700;
      font-size: 18px;
      line-height: 1.4; }
  #main .rank .comment p {
    padding: 0 15px 10px;
    font-size: 18px;
    line-height: 1.6; }

#main .rank .more {
  position: relative;
  overflow: hidden;
  padding: 20px 0 15px;
  text-align: center;
  letter-spacing: -.40em;
  font-size: 0; }
  #main .rank .more span {
    display: inline-block;
    margin-right: 20px;
    width: 256px;
    height: 53px;
    vertical-align: bottom;
    letter-spacing: normal;
    font-size: 14px; }
  #main .rank .more a {
    display: block;
    position: relative;
    overflow: hidden;
    height: 53px;
    border: 1px solid #666;
    border-radius: 4px;
    box-shadow: 2px 2px 2px 1px rgba(78, 78, 78, 0.35);
    padding-left: 1em;
    background: lime;
    background: linear-gradient(to bottom, lime 0%, #00e500 10%, #00e500 90%, #00cc00 100%);
    line-height: 53px;
    color: #fff;
    font-size: 1.1rem;
    font-weight: 900;
    text-decoration: none; }
    #main .rank .more a::before {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: .6em;
      width: 1.2em;
      height: 1.2em;
      margin: auto 0;
      background-image: url(img/button_more_icon.png);
      background-size: contain;
      background-repeat: no-repeat; }
  #main .rank .more span.official {
    margin-right: 0; }
    #main .rank .more span.official a {
      background: #ffc166;
      background: linear-gradient(to bottom, #ffc166 0%, #ffa826 10%, #ffa826 90%, #f29f24 100%); }
      #main .rank .more span.official a::before {
        background-image: url(img/button_official_icon.png); }

@media screen and (max-width: 640px) {
  #main .point1, #main .point2, #main .point3, #main .point4, #main .point5, #main .point6, #main .point7, #main .point8, #main .point9, #main .point10 {
    padding-left: 76px;
    background-size: 74px 28px; } }

/*
==================================================
ポイント＆ランキング
==================================================
*/
/* ポイント＆ランキング
==================================================
*/
@media all and (-webkit-min-device-pixel-ratio: 1.5) {
  #main .rank1 {
    background-image: url(img/rank1@2x.png); }
  #main .rank2 {
    background-image: url(img/rank2@2x.png); }
  #main .rank3 {
    background-image: url(img/rank3@2x.png); }
  #main .rank4 {
    background-image: url(img/rank4@2x.png); }
  #main .rank5 {
    background-image: url(img/rank5@2x.png); }
  #main .rank6 {
    background-image: url(img/rank6@2x.png); }
  #main .rank7 {
    background-image: url(img/rank7@2x.png); }
  #main .rank8 {
    background-image: url(img/rank8@2x.png); }
  #main .rank9 {
    background-image: url(img/rank9@2x.png); }
  #main .rank10 {
    background-image: url(img/rank10@2x.png); }
  #main .rank1, #main .rank2, #main .rank3, #main .rank4, #main .rank5, #main .rank6, #main .rank7, #main .rank8, #main .rank9, #main .rank10 {
    background-size: 49px 52px; } }

@media screen and (max-width: 899px) {
  #main .rankbox-inner {
    padding: 10px 0 16px 0;
    line-height: 1.3; }
    #main .rankbox-inner .float-l {
      float: none; }
      #main .rankbox-inner .float-l p {
        text-align: center; }
  #main .rank .more span {
    display: block;
    margin: 0 auto 20px;
    width: 90%;
    height: 45px;
    vertical-align: bottom;
    letter-spacing: normal;
    font-size: 14px; }
    #main .rank .more span.official {
      margin: 0 auto; }
  #main .rank .more a {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0 auto;
    width: 100%;
    height: 45px;
    line-height: 45px; } }

@media screen and (max-width: 640px) {
  #main .rank1, #main .rank2, #main .rank3, #main .rank4, #main .rank5, #main .rank6, #main .rank7, #main .rank8, #main .rank9, #main .rank10 {
    font-size: 20px; }
  #main .rankbox .rankbox-inner .float-l, #main .rankbox .rankbox-inner .float-r {
    float: none;
    overflow: hidden;
    padding-bottom: 10px;
    text-align: center; }
  #main .rankbox .float-l img, #main .rankbox .float-r img {
    margin: 0;
    padding: 0;
    height: auto;
    max-width: 200px; }
  #main .rank-inner {
    margin: 0;
    padding: 10px 12px; }
  #main .rank1, #main .rank2, #main .rank3, #main .rank4, #main .rank5, #main .rank6, #main .rank7, #main .rank8, #main .rank9, #main .rank10 {
    line-height: 1.3; }
  #main .rank .comment {
    padding: 5px 3px; }
    #main .rank .comment p {
      padding: 0 10px 10px;
      font-size: 16px;
      line-height: 1.6; } }

/* サイドメニューランキング
==================================================
*/
#menu .rank1, #menu .rank2, #menu .rank3, #menu .rank4, #menu .rank5 {
  margin: 0;
  height: auto;
  border: medium none;
  background-position: left top;
  background-repeat: no-repeat;
  color: #333;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.5; }

#menu .rank1, #menu .rank2, #menu .rank3 {
  padding: 18px 0 18px 65px; }

#menu .rank4, #menu .rank5 {
  padding: 10px 0 15px 65px; }

#menu .rank1 {
  background-image: url(img/rank1.png); }

#menu .rank2 {
  background-image: url(img/rank2.png); }

#menu .rank3 {
  background-image: url(img/rank3.png); }

#menu .rank4 {
  background-image: url(img/rank4.png); }

#menu .rank5 {
  background-image: url(img/rank5.png); }

/*
==================================================
カラム１
==================================================
*/
#column1 {
  background-image: url(img/column_bg.gif);
  background-position: center top;
  background-repeat: no-repeat;
  text-align: center; }
  #column1 #menu {
    float: none;
    width: auto; }
  #column1 #main {
    float: none;
    width: auto;
    max-width: 970px;
    margin-left: 0;
    margin-bottom: 10px;
    padding: 0 20px 20px; }
  #column1 #menu {
    margin-right: 10px; }
    #column1 #menu .menulist {
      padding: 0; }
    #column1 #menu .menubox {
      padding-top: 10px; }
    #column1 #menu ul {
      padding: 5px 10px;
      background: none repeat scroll 0 0 transparent;
      letter-spacing: -.4em; }
    #column1 #menu li {
      display: inline-block;
      padding: 0 0 0 4px;
      border: none;
      background-image: url(img/menu_bg.png);
      background-position: left 5px;
      background-repeat: no-repeat;
      letter-spacing: normal; }
      #column1 #menu li a {
        display: inline;
        padding: 0;
        background: none repeat scroll 0 0 transparent;
        text-decoration: none;
        font-weight: 400; }
    #column1 #menu #newEntry li a {
      display: inline;
      padding: 0;
      background: none repeat scroll 0 0 transparent;
      text-decoration: none;
      font-weight: 400; }
    #column1 #menu li a:hover, #column1 #menu #newEntry li a:hover {
      text-decoration: underline; }
    #column1 #menu .menulist li a, #column1 #menu .menulist li.l2 a {
      margin: 0 5px;
      padding: 0 5px; }
  #column1 .menulist #menu li.l3 a {
    margin: 0 5px;
    padding: 0 5px; }
  #column1 #menu ul#newEntry li, #column1 #menu ul#entryarchive li {
    margin: 0 5px;
    padding: 0 5px;
    background-image: none; }
  #column1 #rmenu ul#newEntry li, #column1 #rmenu ul#entryarchive li {
    margin: 0 5px;
    padding: 0 5px;
    background-image: none; }

@media screen and (max-width: 899px) {
  #column1 {
    background: none repeat scroll 0 0 transparent; } }

@media screen and (max-width: 640px) {
  #column1 #main {
    margin-left: 0;
    padding-bottom: 0; }
  #column1 #menu {
    margin-right: 0; }
    #column1 #menu ul {
      position: relative;
      padding: 0 0 5px;
      letter-spacing: normal; }
      #column1 #menu ul#newEntry {
        position: relative;
        padding: 0 0 10px 0;
        background: none repeat scroll 0 0 transparent; }
  #menu ul#entryarchive {
    position: relative;
    padding: 0 0 10px 0;
    background: none repeat scroll 0 0 transparent; }
  #rmenu ul#newEntry, #rmenu ul#entryarchive {
    position: relative;
    padding: 0 0 10px 0;
    background: none repeat scroll 0 0 transparent; }
  #column1 #menu .menubox {
    padding-top: 0; }
  #column1 .menulist ul {
    position: relative;
    background: none repeat scroll 0 0 transparent; }
  #column1 #menu li {
    position: relative;
    display: block;
    padding: 0;
    border-bottom: 1px solid #e6e6e6;
    background: none repeat scroll 0 0 transparent;
    line-height: 1.5; }
  #column1 #menu .menulist .l2, #column1 #menu .menulist .l3 {
    display: none; }
  #column1 #menu li a {
    display: block;
    padding: 12px 10px 12px 35px;
    background-image: url(img/menu_bg.png);
    background-position: 20px 17px;
    background-repeat: no-repeat;
    color: #333;
    text-decoration: none;
    font-size: 14px; }
    #column1 #menu li a:hover {
      color: #003580;
      text-decoration: underline; }
  #column1 #menu .menulist li a, #column1 #menu .menulist li.l2 a {
    margin: 0;
    padding: 12px 10px 12px 35px; }
  #column1 .menulist #menu li.l3 a {
    margin: 0;
    padding: 12px 10px 12px 35px; }
  #column1 #menu ul#newEntry li, #column1 #menu ul#entryarchive li {
    margin: 0;
    padding: 15px 10px; }
  #column1 #rmenu ul#newEntry li, #column1 #rmenu ul#entryarchive li {
    margin: 0;
    padding: 15px 10px; } }

/*
==================================================
カラム２
==================================================
*/
#column2 {
  text-align: center; }

@media screen and (max-width: 899px) {
  #column2 {
    background: none repeat scroll 0 0 transparent; } }

/*
==================================================
カラム調整
==================================================
*/
#column1 #rmenu, #column2 #rmenu {
  display: none; }

#column1 #space3, #column1 #space4, #column1 .bannerbox, #column1 #submenu {
  display: none; }

#column3 #rmenu {
  float: left; }

#column3 #layoutbox {
  float: right; }

#column3 #main {
  float: left; }

/*--------------------------------
   SNS
---------------------------------*/
.sns {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  margin: 0 auto 1em;
  padding: 0;
  width: 100%;
  list-style: none; }

.sns li {
  margin: 5px .5rem;
  padding: 0;
  background-image: none;
  list-style-type: none; }

.sns li a {
  text-decoration: none; }

.sns li a img {
  width: 50px; }

.sns li a span {
  display: none; }

.fa-chevron-down::before {
  content: ""; }

@media screen and (max-width: 899px) {
  ul.sns li::before {
    display: none; } }

.hatena {
  display: none; }

@media only screen and (min-width: 641px) {
  .sns li a {
    display: block;
    width: 90px;
    height: 40px;
    border-radius: 4px; }
  .sns li a img {
    display: none; }
  .sns li a span {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    padding-left: 1em;
    width: 100%;
    color: #fff;
    text-align: center;
    font-size: .8em;
    line-height: 40px; }
  .sns .fa {
    font-family: "Droid Sans", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", メイリオ, Meiryo, Roboto, Verdana, sans-serif; }
  #facebook span::before, #twitter span::before, #googleplus span::before, #hatena span::before, #line span::before, #pocket span::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 8px;
    display: block;
    margin: auto 0;
    width: 1em;
    height: auto;
    color: #fff;
    font-size: 20px;
    font-family: FontAwesome; }
  #facebook {
    border-bottom: 2px solid #18274a;
    background-color: #315096; }
  #facebook span::before {
    content: "\f09a"; }
  #twitter {
    border-bottom: 2px solid #38709c;
    background: #55acee; }
  #twitter span::before {
    content: "\f099"; }
  #googleplus {
    border-bottom: 2px solid #8d2d1d;
    background-color: #dd4b39; }
  .sns li a#googleplus span {
    padding-left: 1.5em; }
  #googleplus span::before {
    content: "\f0d5"; }
  .hatena {
    display: block; }
  #hatena {
    border-bottom: 2px solid #2d558a;
    background-color: #4888db; }
  #hatena span::before {
    background: url(img/share_hatena_pc.png) center 12px no-repeat;
    background-size: contain;
    content: ""; }
  #line {
    border-bottom: 2px solid #1b7510;
    background-color: #2dc81d; }
  #line span::before {
    background: url(img/share_line_pc.png) center 11px no-repeat;
    background-size: contain;
    content: ""; }
  #pocket {
    border-bottom: 2px solid #992938;
    background-color: #ee4056; }
  #pocket span::before {
    background: url(img/share_pocket_pc.png) center center no-repeat;
    background-size: contain;
    content: ""; }
  #pocket a:hover {
    color: #fff; } }

@media only screen and (min-width: 767px) {
  .line {
    display: none; } }

@media only screen and (min-width: 900px) {
  .sns li a {
    width: 110px; } }
